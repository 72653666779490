import React from 'react'
import {
    Button,
    MenuItem,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import firestoreService from '../../firebase/firestoreService';
import { NFD_CHILD_ID_OPTIONS } from '../../constant/index';
import ModalReusable from '.'

const validationSchema = Yup.object().shape({
    parentNfdStatus: Yup.string().required('Parent is required'),
    childNfdStatus: Yup.string().required('Child is required'),
    nfd: Yup.date().required('NFD is required'),
    status: Yup.string().required('Status is required'),
    remark: Yup.string().required('Remark is required'),
});


const EditNfdModal = ({ isOpenForNFDModal, setIsOpenForNFDModal, selectedEnquiry, setSelectedEnquiry, setENQUIRYLIST, ENQUIRYLIST }) => {
    const theme = useTheme();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formik = useFormik({
        initialValues: {
            parentNfdStatus: '',
            childNfdStatus: '',
            nfd: '',
            status: '',
            remark: '',
        },
        validationSchema,
        onSubmit: (values) => {
            // Handle form submission
            handleUpdateNFDForEnquiry(values);
        },
    });

    const handleCloseModal = () => {
        setIsOpenForNFDModal(false);
        setSelectedEnquiry(null);
        formik.resetForm();
    };

    const handleUpdateNFDForEnquiry = async (values) => {
        try {
            let remarkHistory;
            const remarkHistoryOldData = {
                userId: currentUser.id,
                date: moment().format(),
                remark: values?.remark
            };
            if (selectedEnquiry?.remarkHistory) {
                remarkHistory = [...selectedEnquiry?.remarkHistory, remarkHistoryOldData];
            } else {
                remarkHistory = [remarkHistoryOldData];
            }
            const addNfdOnEnquiry = { ...selectedEnquiry, ...values, remarkHistory };
            if (addNfdOnEnquiry.status === 'deal') {
                const addToDealDone = await firestoreService.setDocument('property-deals', selectedEnquiry.id, addNfdOnEnquiry);
                if (addToDealDone) {
                    const deleteEnquiry = await firestoreService.deleteDocument('enquiries', selectedEnquiry.id);
                    if (deleteEnquiry) {
                        setENQUIRYLIST(ENQUIRYLIST.filter(enquiry => enquiry?.id !== selectedEnquiry?.id));
                        formik.resetForm();
                        handleCloseModal();
                        setSelectedEnquiry(null);
                        toast.success('Enquiry sent to Deal done successfully');
                    }
                } else {
                    toast.error('something went wrong')
                }
            } else {
                const updateEnquiry = await firestoreService.updateDocument('enquiries', selectedEnquiry.id, addNfdOnEnquiry);
                if (updateEnquiry) {
                    setENQUIRYLIST(ENQUIRYLIST.map(enquiry => enquiry?.id === selectedEnquiry?.id ? { id: selectedEnquiry.id, ...addNfdOnEnquiry } : enquiry));
                    formik.resetForm();
                    handleCloseModal();
                    setSelectedEnquiry(null);
                    toast.success('Enquiry updated successfully');
                } else {
                    toast.error('something went wrong')
                }
            }
        } catch (error) {
            console.error('error', error)
        }
    }
    return (
        <ModalReusable
            open={isOpenForNFDModal}
            onClose={handleCloseModal}
            header={`Add New Followup ${selectedEnquiry?.name} ${selectedEnquiry?.mobileNo}`}
        >
            <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="parentNfdStatus">Parent:</InputLabel>
                                <Select
                                    id="parentNfdStatus"
                                    name="parentNfdStatus"
                                    value={formik.values.parentNfdStatus}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <MenuItem value={"phone_call"}>Phone Call</MenuItem>
                                    <MenuItem value={"visit"}>Visit</MenuItem>
                                    <MenuItem value={"meeting"}>Meeting</MenuItem>
                                    <MenuItem value={"deal"}>Deal</MenuItem>
                                </Select>
                                {formik.touched.parentNfdStatus && formik.errors.parentNfdStatus && (
                                    <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.parentNfdStatus}</Box>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="childNfdStatus">Child:</InputLabel>
                                <Select
                                    id="childNfdStatus"
                                    name="childNfdStatus"
                                    value={formik.values.childNfdStatus}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    {NFD_CHILD_ID_OPTIONS[formik.values.parentNfdStatus]?.map((nfdChild, index) => (
                                        <MenuItem value={nfdChild} key={index}>{nfdChild}</MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.childNfdStatus && formik.errors.childNfdStatus && (
                                    <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.childNfdStatus}</Box>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="nfd"
                                    name="nfd"
                                    type="date"
                                    label="NFD:"
                                    value={moment(formik.values.nfd).format('YYYY-MM-DD').toString()}
                                    onChange={(event) => {
                                        const selectedDate = moment(event.target.value).format();
                                        formik.setFieldValue('nfd', selectedDate);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.nfd && formik.errors.nfd && (
                                    <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.nfd}</Box>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="status">Status:</InputLabel>
                                <Select
                                    id="status"
                                    name="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <MenuItem value={"open"}>Open</MenuItem>
                                    <MenuItem value={"close"}>Close</MenuItem>
                                    <MenuItem value={"hold"}>Hold</MenuItem>
                                    <MenuItem value={"deal"}>Deal Done</MenuItem>
                                </Select>
                                {formik.touched.status && formik.errors.status && (
                                    <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.status}</Box>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    multiline
                                    rows={3}
                                    label="Remark:"
                                    placeholder="Enter Remark"
                                    value={formik.values.remark}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.remark && formik.errors.remark && (
                                    <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.remark}</Box>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <input type="hidden" name="Action" value="AddNewComment" />
                    <input type="hidden" name="id" id="enquiryid" value="28" />
                    <div style={{ marginTop: '15px' }}>
                        <Button type="submit" color="primary" variant="contained">
                            Add
                        </Button>
                    </div>
                </form>
            </Box>
        </ModalReusable>
    )
}

export default EditNfdModal;
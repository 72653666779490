import React, { useEffect, useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer, Modal, Box, TextField, FormControl, InputLabel, Select, CircularProgress, FormControlLabel, Checkbox, Divider, Chip,
} from '@mui/material';
// import { convert } from 'currency-in-words';
import NumberToWord from 'convert-rupees-into-words';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { titleCase } from '../../utils/index';
import firestoreService, { sendNotification } from '../../firebase/firestoreService';
import ModalReusable from '.';


const EditEnquiryModal = ({ selectedEnquiry, setSelectedEnquiry, enquiryModalFlag, setEnquiryModalFlag, userList, isOpenForEnquiryShow, setIsOpenForEnquiryShow, ENQUIRYLIST, setENQUIRYLIST, AREA_NAME }) => {
    const theme = useTheme();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [segmentOptions, setSegmentOption] = useState({});
    const [subSegmentOptions, setSubSegmentOption] = useState({});


    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        mobileNo: Yup.string().matches(/^[0-9]+$/, 'Mobile No. must be a valid number').required('Mobile number is required').min(10).max(10),
        // email: Yup.string().email('Invalid email address').required('Email is required'),
        enquiryFor: Yup.string().required('For is required'),
        source: Yup.string().required('Source is required'),
        propertyType: Yup.string().required('Property Type is required'),
        segment: Yup.string().required('Segment is required'),
        measureMent: Yup.string().required('Measurement is required'),
        unit: Yup.string().required('Unit is required'),
        bhkOffice: Yup.string().required('BHK/Office is required'),
        status: Yup.string().required('Status is required'),
        budget: Yup.string().required('Budget is required'),
        area: Yup.array().required('Area is required'),
        remark: Yup.string().required('Remark is required'),
        assignTo: Yup.string().required('AssignTo is required'),
        nfd: Yup.string().required('NFD is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: selectedEnquiry?.name || '',
            mobileNo: selectedEnquiry?.mobileNo || '',
            email: selectedEnquiry?.email || '',
            enquiryFor: selectedEnquiry?.enquiryFor || '',
            source: selectedEnquiry?.source || '',
            propertyType: selectedEnquiry?.propertyType || '',
            segment: selectedEnquiry?.segment || '',
            measureMent: selectedEnquiry?.measureMent || '',
            unit: selectedEnquiry?.unit || '',
            bhkOffice: selectedEnquiry?.bhkOffice || '',
            status: selectedEnquiry?.status || '',
            budget: selectedEnquiry?.budget || '',
            area: selectedEnquiry?.area || [],
            remark: selectedEnquiry?.remark || '',
            assignTo: selectedEnquiry?.assignTo || '',
            nfd: moment(selectedEnquiry?.nfd).format('YYYY-MM-DD').toString() || '',
            createdAt: selectedEnquiry?.createdAt || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (selectedEnquiry) {
                handleUpadateEnquiry(values);
            } else {
                handleCreateEnquiry(values);
            }
        },
    });

    useEffect(() => {
        fetchAllSegmentMenu();

        return () => {
            setSegmentOption({});
            setSubSegmentOption({});
        }
    }, [])

    const fetchAllSegmentMenu = async () => {
        try {
            const options = await firestoreService.getDocument('default-values', 'segment');
            const { segmentOptions, subSegmentOptions } = options;
            if (subSegmentOptions) {
                setSegmentOption(segmentOptions)
                setSubSegmentOption(subSegmentOptions);
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleCreateEnquiry = async (values) => {
        try {
            const whereClause = { field: 'mobileNo', operator: '==', value: values.mobileNo };
            const checkMobileForEnquiryData = await firestoreService.findOneDocument('enquiries', whereClause);
            if (checkMobileForEnquiryData) {
                toast.error('Mobile number is already exist');
            } else {
                const remarkHistory = [{
                    userId: currentUser.id,
                    date: moment(new Date()).format(),
                    remark: `Enquiry created`
                }];
                const valueWithHistory = { ...values, remarkHistory };
                const enquiryData = await firestoreService.addDocument('enquiries', valueWithHistory);
                if (enquiryData) {
                    setENQUIRYLIST(prev => [enquiryData, ...prev])
                    formik.resetForm();
                    handleCloseModal();
                    toast.success('Enquiry created successfully');
                    // for send notification to assign user
                    const whereClause = { field: 'id', operator: '==', value: values?.assignTo };
                    const userData = await firestoreService.findOneDocument('users', whereClause);
                    if (userData?.token) {
                        await sendNotification(userData?.token,
                            'New Enquiry Assigned',
                            'A new enquiry has been assigned to your account. Please review the details.');
                    }
                } else {
                    toast.error('something went wrong')
                }
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleUpadateEnquiry = async (values) => {
        try {
            const userData = await firestoreService.updateDocument('enquiries', selectedEnquiry.id, values);
            if (userData) {
                setENQUIRYLIST(ENQUIRYLIST.map(user => user?.id === selectedEnquiry?.id ? { id: selectedEnquiry.id, ...values } : user));
                formik.resetForm();
                handleCloseModal();
                setSelectedEnquiry(null);
                toast.success('Enquiry updated successfully');
                // for send notification to assign user
                const whereClause = { field: 'id', operator: '==', value: values?.assignTo };
                const userData = await firestoreService.findOneDocument('users', whereClause);
                if (userData?.token) {
                    await sendNotification(userData?.token,
                        'New Enquiry Assigned',
                        'A new enquiry has been assigned to your account. Please review the details.');
                }
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleCloseModal = () => {
        setIsOpenForEnquiryShow(false);
        setEnquiryModalFlag(false);
        setSelectedEnquiry(null);
        formik.resetForm();
    };

    return (
        <ModalReusable
            open={enquiryModalFlag}
            onClose={handleCloseModal}
            header={isOpenForEnquiryShow ? "Enquiry Details " : (selectedEnquiry?.id ? 'Update Enquiry' : 'Add Enquiry')}
        >
            {isOpenForEnquiryShow ?
                <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4 }}>
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Added At:</b> {moment(selectedEnquiry?.createdAt).format('DD-MM-YYYY')}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>For:</b> {selectedEnquiry?.enquiryFor}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Name:</b> {selectedEnquiry?.name}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Mobile:</b> {selectedEnquiry?.mobileNo}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Type:</b> {selectedEnquiry?.propertyType}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Segment:</b> {selectedEnquiry?.segment}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Bhk-Floor:</b> {selectedEnquiry?.bhkOffice}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Last Remark:</b> {selectedEnquiry?.remark}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Next Followup Date:</b> {moment(selectedEnquiry?.nfd).format('DD-MM-YYYY')}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Budget:</b> {selectedEnquiry?.budget}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Sq.ft/Sq.yd:</b> {`${selectedEnquiry?.measureMent} ${selectedEnquiry?.unit}`}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Status:</b> {selectedEnquiry?.status}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Source:</b> {titleCase(selectedEnquiry?.source)}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Assign To:</b> {userList.find(user => user.id === selectedEnquiry?.assignTo)?.name}</Typography>
                    </FormControl>
                    <Divider />
                    <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Area:</b></Typography>
                        <ul>
                            {selectedEnquiry?.area.map((areaId, index) => (
                                <li key={index}>{AREA_NAME?.find((area) => area.id === areaId)?.name}</li>
                            ))}
                        </ul>
                    </FormControl>
                    <Divider />
                    {selectedEnquiry?.remarkHistory?.length ? <FormControl fullWidth margin="normal">
                        <Typography variant="body1"><b>Enquiry History:</b></Typography>
                        <ul>
                            {selectedEnquiry?.remarkHistory?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map(({ userId, date, remark }, index) => (
                                <li key={index}>
                                    <span style={{ display: 'inline-flex', marginRight: '5px', fontWeight: 'bold' }}>{userList.find(user => user.id === userId)?.name} ({moment(date).format('DD-MM-YY hh:mm')}) :</span>
                                    <span>{remark}</span>
                                </li>
                            ))}
                        </ul>
                    </FormControl> : null}
                </Box>
                :
                <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Mobile Number"
                            variant="outlined"
                            name="mobileNo"
                            value={formik.values.mobileNo}
                            onChange={formik.handleChange}
                            error={formik.touched.mobileNo && Boolean(formik.errors.mobileNo)}
                            helperText={formik.touched.mobileNo && formik.errors.mobileNo}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>For</InputLabel>
                            <Select
                                labelId="enquiryFor-label"
                                id="enquiryFor"
                                label="For"
                                name="enquiryFor"
                                value={formik.values.enquiryFor}
                                onChange={formik.handleChange}
                                error={formik.touched.enquiryFor && Boolean(formik.errors.enquiryFor)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="rent">Rent</MenuItem>
                                <MenuItem value="buy">Buy</MenuItem>
                            </Select>
                            {formik.touched.enquiryFor && formik.errors.enquiryFor && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.enquiryFor}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Source</InputLabel>
                            <Select
                                labelId="source-label"
                                id="source"
                                label="Select Source"
                                name="source"
                                value={formik.values.source}
                                onChange={formik.handleChange}
                                error={formik.touched.source && Boolean(formik.errors.source)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="reference">Reference</MenuItem>
                                <MenuItem value="magicbricks">Magicbricks</MenuItem>
                                <MenuItem value="99Acres">99Acres</MenuItem>
                                <MenuItem value="phone_call">Phone call</MenuItem>
                                <MenuItem value="news_paper">News paper</MenuItem>
                                <MenuItem value="housing.com">Housing.com</MenuItem>
                                <MenuItem value="builder">Builder</MenuItem>
                                <MenuItem value="broker">Broker</MenuItem>
                                <MenuItem value="techno">Techno</MenuItem>
                                <MenuItem value="facebook">Facebook</MenuItem>
                            </Select>
                            {formik.touched.source && formik.errors.source && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.source}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Property Type</InputLabel>
                            <Select
                                labelId="property-type-label"
                                id="propertyType"
                                label="Select Property Type"
                                name="propertyType"
                                value={formik.values.propertyType}
                                onChange={
                                    formik.handleChange}
                                error={formik.touched.propertyType && Boolean(formik.errors.propertyType)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="residential">Residential</MenuItem>
                                <MenuItem value="commercial">Commercial</MenuItem>
                                <MenuItem value="plot">Plot</MenuItem>
                            </Select>
                            {formik.touched.propertyType && formik.errors.propertyType && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.propertyType}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Segment:</InputLabel>
                            <Select
                                labelId="segment-label"
                                id="segment"
                                label="Segment:"
                                name="segment"
                                value={formik.values.segment}
                                onChange={formik.handleChange}
                                error={formik.touched.segment && Boolean(formik.errors.segment)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {segmentOptions[formik.values.propertyType]?.map((sagment, index) => (
                                    <MenuItem value={sagment} key={index}>{sagment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.segment && formik.errors.segment && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.segment}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    value={formik.values.unit}
                                    onChange={formik.handleChange}
                                    name="unit"
                                    variant="outlined"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Unit' }}
                                    style={{ marginRight: '8px', width: '80px' }}
                                >
                                    <MenuItem value="sqft">sqft</MenuItem>
                                    <MenuItem value="sqyd">sqyd</MenuItem>
                                </Select>
                                <TextField
                                    type="number"
                                    name="measureMent"
                                    value={formik.values.measureMent}
                                    onChange={formik.handleChange}
                                    error={formik.touched.measureMent && Boolean(formik.errors.measureMent)}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    label="Sq feet / Sq Yard ?:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: 0, // Set the minimum allowed value
                                        },
                                    }}
                                />
                            </div>
                            {formik.touched.measureMent && formik.errors.measureMent && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.measureMent}</Box>
                            )}
                            {formik.touched.unit && formik.errors.unit && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.unit}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Bhk/Office:</InputLabel>
                            <Select
                                labelId="bhk-label"
                                id="bhkOffice"
                                label="Bhk/Office:"
                                name="bhkOffice"
                                value={formik.values.bhkOffice}
                                onChange={formik.handleChange}
                                error={formik.touched.bhkOffice && Boolean(formik.errors.bhkOffice)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {subSegmentOptions[formik.values.segment]?.map((subSegment, index) => (
                                    <MenuItem value={subSegment} key={index}>{subSegment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.bhkOffice && formik.errors.bhkOffice && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.bhkOffice}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Status:</InputLabel>
                            <Select
                                labelId="status-label"
                                id="status"
                                label="Status:"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="open">Open</MenuItem>
                                <MenuItem value="close">Close</MenuItem>
                                <MenuItem value="hold">Hold</MenuItem>
                                {selectedEnquiry?.id ? <MenuItem value="deal">Deal Done</MenuItem> : null}
                            </Select>
                            {formik.touched.status && formik.errors.status && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.status}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                type="number"
                                name="budget"
                                value={formik.values.budget}
                                onChange={formik.handleChange}
                                error={formik.touched.budget && Boolean(formik.errors.budget)}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                label="Budget:"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {formik.touched.budget && formik.errors.budget && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.budget}</Box>
                            )}
                            <Typography variant='h6' fontSize={10}>{formik?.values?.budget && NumberToWord(formik?.values?.budget)}</Typography>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Area:</InputLabel>
                            <Select
                                labelId="area-label"
                                id="area"
                                label="Area:"
                                name="area"
                                value={formik.values.area}
                                onChange={formik.handleChange}
                                error={formik.touched.area && Boolean(formik.errors.area)}
                                onBlur={formik.handleBlur}
                                multiple  // Add the 'multiple' attribute to enable multiple selection
                                renderValue={(selected) => (
                                    <div>
                                        {selected?.map((value) => (
                                            <Chip key={value} label={AREA_NAME?.find((area) => area?.id === value).name} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {AREA_NAME?.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }, index) => (
                                    <MenuItem value={id} key={index}>{name}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.area && formik.errors.area && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.area}</Box>
                            )}
                        </FormControl>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Remark"
                            variant="outlined"
                            name="remark"
                            value={formik.values.remark}
                            onChange={formik.handleChange}
                            error={formik.touched.remark && Boolean(formik.errors.remark)}
                            helperText={formik.touched.remark && formik.errors.remark}
                            multiline
                            rows={4}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Assign To</InputLabel>
                            <Select
                                labelId="assign-to-label"
                                id="assignTo"
                                label="Assign To"
                                name="assignTo"
                                value={formik.values.assignTo}
                                onChange={formik.handleChange}
                                error={formik.touched.assignTo && Boolean(formik.errors.assignTo)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {['admin', 'back_office'].includes(currentUser?.role) ? userList.map((user, index) => (
                                    <MenuItem value={user.id} key={index}>{user.name}</MenuItem>
                                )) : <MenuItem value={currentUser.id}>{currentUser.name}</MenuItem>}
                            </Select>
                            {formik.touched.assignTo && formik.errors.assignTo && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.assignTo}</Box>
                            )}
                        </FormControl>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="NFD (Next Follow-up Date)"
                            variant="outlined"
                            type="date"
                            name="nfd"
                            value={moment(formik.values.nfd).format('YYYY-MM-DD').toString()}
                            onChange={(event) => {
                                const selectedDate = moment(event.target.value).format();
                                formik.setFieldValue('nfd', selectedDate);
                            }}
                            error={formik.touched.nfd && Boolean(formik.errors.nfd)}
                            helperText={formik.touched.nfd && formik.errors.nfd}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <Button variant="contained"
                            type='submit'
                            size='medium'
                            sx={{ marginTop: '10px' }}
                        >
                            {selectedEnquiry?.id ? 'Update' : 'Add'} Enquiry
                        </Button>
                    </form>
                </Box>}
        </ModalReusable>
    )
}

export default EditEnquiryModal
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer, Switch, Box, TextField, FormControl, InputLabel, Select, CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ACTION_TYPE, ENTITIES_TYPE, USER_ROLES } from '../constant/index';
import Permission from '../components/permission/index';
import firestoreService from '../firebase/firestoreService';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import ModalReusable from '../components/modal';
import { handleCheckPermissionByRole } from '../utils/index';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'id', label: 'User Id', alignRight: false },
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'employeeType', label: 'Employee Type', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'mobileNo', label: 'Mobile No.', alignRight: false },
  { id: 'password', label: 'Password', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'loginTime', label: 'Last Login', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------


export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [USERLIST, setUSERLIST] = useState([])
  const [selectedUser, setSelectedUser] = useState(null);
  const [userModalFlag, setUserModalFlag] = useState(false);
  const [permissionModalFlag, setPermissionModalFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme();
  useEffect(() => {
    fetchAllUsers();

    return () => setUSERLIST([]);
  }, [])

  const fetchAllUsers = async () => {
    try {
      setIsLoading(true);
      const userList = await firestoreService.getAllDocuments('users');
      if (userList.length) {
        setUSERLIST(userList);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('error', error)
      setIsLoading(false);
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    role: Yup.string().required('Role is required'),
    status: Yup.string().required('Status is required'),
    employeeType: Yup.string().required('Employee Type is required'),
    userName: Yup.string().required('Username is required'),
    mobileNo: Yup.string().matches(/^[0-9]+$/, 'Mobile No. must be a valid number').required('Mobile number is required').min(10).max(10),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: selectedUser?.name || '',
      role: selectedUser?.role || '',
      status: selectedUser?.status || '',
      employeeType: selectedUser?.employeeType || '',
      userName: selectedUser?.userName || '',
      mobileNo: selectedUser?.mobileNo || '',
      password: selectedUser?.password || '',
      confirmPassword: selectedUser?.confirmPassword || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (selectedUser) {
        handleUpadateUser(values);
      } else {
        handleCreateUser(values);
      }
    },
  });
  const handleCreateUser = async (values) => {
    try {
      const userData = await firestoreService.addDocument('users', values);
      if (userData) {
        setUSERLIST(prev => [...prev, userData])
        formik.resetForm();
        handleCloseModal();
        toast.success('User created successfully');
      } else {
        toast.error('something went wrong')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleUpadateUser = async (values) => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const userValue = { ...selectedUser, ...values };
      const userData = await firestoreService.updateDocument('users', selectedUser.id, userValue);
      if (userData) {
        if (currentUser?.userName === values?.userName) {
          localStorage.setItem('currentUser',JSON.stringify(userValue));
        }
        setUSERLIST(USERLIST.map(user => user?.id === selectedUser?.id ? { id: selectedUser.id, ...userValue } : user));
        formik.resetForm();
        handleCloseModal();
        setSelectedUser(null);
        toast.success('User updated successfully');
      } else {
        toast.error('something went wrong')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleDeleteUser = async (id) => {
    try {
      const response = await firestoreService.deleteDocument('users', id);
      if (response) {
        setUSERLIST(USERLIST.filter(user => user.id !== selectedUser?.id));
        setSelectedUser(null);
        handleCloseMenu();
        toast.success('User deleted successfully');
      } else {
        toast.error('something went wrong')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleOpenModal = () => {
    setUserModalFlag(true);
    setOpen(false);
  };

  const handleOpenModalForPermission = () => {
    setPermissionModalFlag(true)
  }
  const handleCloseModal = () => {
    setSelectedUser(null);
    setUserModalFlag(false);
    formik.resetForm();
  };

  const handleCloseModalForPermission = () => {
    setPermissionModalFlag(false);
  };

  const handleOpenMenu = (event, data) => {
    setOpen(event.currentTarget);
    setSelectedUser(data);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedUser(null);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleUserStatusChange = async (event, values) => {
    try {
      const changeUserStatus = {
        ...values,
        status: event.target.checked ? 'active' : 'banned',
      };
      const userData = await firestoreService.updateDocument('users', values.id, changeUserStatus);
      if (userData) {
        setUSERLIST(USERLIST.map(user => user?.id === values.id ? changeUserStatus : user));
        toast.success('User updated successfully');
      } else {
        toast.error('something went wrong')
      }
    } catch (error) {
      console.error('error', error)
    }
  };

  function applySearchFilter(array, query) {
    if (query) {
      return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return USERLIST;
  }

  const filteredUsers = applySearchFilter(USERLIST, filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Users | WhiteStone </title>
      </Helmet>

      <Container maxWidth={false} sx={{ marginBottom: '-100px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row">
            {handleCheckPermissionByRole(ENTITIES_TYPE.User, ACTION_TYPE.Add) ? <><Button variant="contained"
              sx={{ marginRight: '10px' }}
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleOpenModal}
            >
              New User
            </Button>
              <Button variant="contained"
                startIcon={<Iconify icon="fluent-mdl2:permissions" />}
                onClick={handleOpenModalForPermission}
              >
                Role Permission
              </Button> </> : null}
          </Stack>
        </Stack>

        <Card>
          <UserListToolbar userCount={filteredUsers?.length} filterName={filterName} onFilterName={handleFilterByName} type="User" />

          <Scrollbar>
            <TableContainer sx={{
              maxHeight: '78vh', // Set the desired fixed height
              overflowY: 'auto', // Add vertical scroll
            }}>
              <Table
                size='small'
                padding='normal'
                stickyHeader>
                <UserListHead
                  headLabel={TABLE_HEAD}
                />
                {isLoading ?
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 10 }}>
                      <Paper>
                        <CircularProgress color="inherit" />
                      </Paper>
                    </TableCell>
                  </TableRow> : <TableBody>
                    {filteredUsers.map((row) => {
                      const { id, name, role, status, employeeType, userName, mobileNo, password, lastLoginTime } = row;
                      return (
                        <TableRow hover key={id} tabIndex={-1} >
                          <TableCell component="th" scope="row" padding="normal" align='center'>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar>{name?.charAt(0)?.toUpperCase()}</Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">{id}</TableCell>
                          <TableCell align="center">{userName}</TableCell>
                          <TableCell align="center">{employeeType}</TableCell>
                          <TableCell align="center">{role}</TableCell>
                          <TableCell align="center">{mobileNo}</TableCell>
                          <TableCell align="center">{password}</TableCell>
                          <TableCell align="center">
                            <Switch
                              checked={status === 'active'}
                              onChange={(event) => handleUserStatusChange(event, row)}
                              inputProps={{ 'aria-label': 'controlled' }}
                              color={'success'}
                              disabled={!handleCheckPermissionByRole(ENTITIES_TYPE.User, ACTION_TYPE.Update)}
                            />
                          </TableCell>
                          <TableCell align="center">{moment(lastLoginTime).format('DD-MM-YY HH:MM')}</TableCell>
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleCheckPermissionByRole(ENTITIES_TYPE.User, ACTION_TYPE.Update) ? handleOpenMenu(e, row) : null}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>}
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {handleCheckPermissionByRole(ENTITIES_TYPE.User, ACTION_TYPE.Update) ? <MenuItem onClick={handleOpenModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem> : null}

        {handleCheckPermissionByRole(ENTITIES_TYPE.User, ACTION_TYPE.Delete) ?
          <MenuItem sx={{ color: 'error.main' }}
            onClick={() => handleDeleteUser(selectedUser?.id)}
            disabled={selectedUser?.role === 'admin' && USERLIST.filter(user => user?.role === 'admin').length <= 1}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> : null}
      </Popover>

      {/* modal for user */}
      <ModalReusable
        open={userModalFlag}
        onClose={handleCloseModal}
        header={selectedUser?.id ? 'Update user' : 'Add user'}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: 24,
            p: 4,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Username"
              variant="outlined"
              name="userName"
              value={formik.values.userName}
              onChange={formik.handleChange}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Mobile Number"
              variant="outlined"
              name="mobileNo"
              value={formik.values.mobileNo}
              onChange={formik.handleChange}
              error={formik.touched.mobileNo && Boolean(formik.errors.mobileNo)}
              helperText={formik.touched.mobileNo && formik.errors.mobileNo}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              variant="outlined"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Confirm Password"
              variant="outlined"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                label="Role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
                onBlur={formik.handleBlur}
              >
                {USER_ROLES.map((role, index) => (
                  <MenuItem value={role.value} key={index}>{role.name}</MenuItem>
                ))}
              </Select>
              {formik.touched.role && formik.errors.role && <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.role}</Box>}
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label="Status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                error={formik.touched.status && Boolean(formik.errors.status)}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="banned">Banned</MenuItem>
              </Select>
              {formik.touched.status && formik.errors.status && <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.status}</Box>}
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Employee Type</InputLabel>
              <Select
                labelId="employeeType-label"
                id="employeeType"
                label="Employee Type"
                name="employeeType"
                value={formik.values.employeeType}
                onChange={formik.handleChange}
                error={formik.touched.employeeType && Boolean(formik.errors.employeeType)}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="CEO">CEO</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="Employee">Employee</MenuItem>
                <MenuItem value="office_assistant">Office Assistant</MenuItem>
              </Select>
              {formik.touched.employeeType && formik.errors.employeeType && <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.employeeType}</Box>}
            </FormControl>
            <Button variant="contained"
              type='submit'
              size='medium'
              sx={{ marginTop: '10px' }}
            >
              {selectedUser?.id ? 'Update' : 'Create'} User
            </Button>
          </form>
        </Box>
      </ModalReusable>

      {/* modal for permission */}
      <ModalReusable
        open={permissionModalFlag}
        onClose={handleCloseModalForPermission}
        header={'Roles permission'}
      >
        <Permission
          handleCloseModalForPermission={handleCloseModalForPermission}
          userRoles={USER_ROLES}
        />
      </ModalReusable>
    </>
  );
}

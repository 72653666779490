import React, { useEffect, useState } from 'react'
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  CircularProgress,
  Box,
  TextField
} from '@mui/material';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import ModalReusable from './index';
import Scrollbar from '../scrollbar/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import Iconify from '../iconify/Iconify';
import { handleCheckPermissionByRole } from '../../utils';
import { ACTION_TYPE, ENTITIES_TYPE } from '../../constant';
import firestoreService from '../../firebase/firestoreService';

const TABLE_HEAD = [
  { id: 'sr.no', label: 'Sr.No.', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'id', label: 'Area Id', alignRight: false },
  { id: '', label: '' },
];

const AreaCrudModal = () => {
  const theme = useTheme();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [open, setOpen] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [areaList, setAreaList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    id: Yup.string().required('Area Id is required'),
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: selectedArea?.name || '',
      id: selectedArea?.id || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (selectedArea?.id) {
        handleUpadateArea(values, 'update');
      } else {
        handleUpadateArea(values, 'create');
      }
    },
  });

  useEffect(() => {
    fetchAllArea();

    return () => {
      setAreaList([]);
    }
  }, [])

  const fetchAllArea = async () => {
    try {
      setIsLoading(true);
      const areaData = await firestoreService.getDocument('default-values', 'area');
      const areaArray = areaData?.area;
      if (areaArray?.length) {
        setAreaList(areaArray);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('error', error)
      setIsLoading(false);
    }
  }

  const handleUpadateArea = async (values, submitType) => {
    try {
      if (submitType === 'create') {
        const findAreaId = areaList.find(area => area.id === values.id || area.name === values.name);
        if (findAreaId) {
          toast.error("Area Id or Name is already exist!")
        } else {
          const areaObjectValue = { area: [values, ...areaList] };
          const areaUpdateData = await firestoreService.updateDocument('default-values', 'area', areaObjectValue);
          if (areaUpdateData) {
            toast.success('Area added successfully!');
            setAreaList(areaObjectValue.area);
            handleCloseModal();
            formik.resetForm();
          } else {
            toast.error('something went wrong');
          }
        }
      } else {
        // for update area details
        const updatedAreaList = [...areaList]; // Create a copy of the original areaList

        const findIndex = areaList.findIndex(area => area.id === selectedArea.id);
        if (findIndex !== -1) {
          // Remove the old area object at the found index
          updatedAreaList.splice(findIndex, 1);

          // Add the updated area object at the same index
          updatedAreaList.splice(findIndex, 0, values);

          // Update the areaList state
          setAreaList(updatedAreaList);

          // Update the Firestore document with the updated areaList
          const areaObjectValue = { area: updatedAreaList };
          const areaUpdateData = await firestoreService.updateDocument('default-values', 'area', areaObjectValue);
          if (areaUpdateData) {
            toast.success('Area updated successfully!');
            handleCloseModal();
            formik.resetForm();
          } else {
            toast.error('Something went wrong');
          }
        } else {
          toast.error('Selected area not found!');
        }
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleOpenModal = () => {
    setIsOpenModal(true);
    setOpen(null);
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    setSelectedArea(null);
  }

  const handleOpenMenu = (event, data) => {
    setOpen(event.currentTarget);
    setSelectedArea(data);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedArea(null);
  };

  const handleDeleteEnquiry = async (id) => {
    const updatedAreaList = areaList.filter(area => area.id !== id);
    const areaObjectValue = { area: updatedAreaList };
    const areaUpdateData = await firestoreService.updateDocument('default-values', 'area', areaObjectValue);
    if (areaUpdateData) {
      setAreaList(updatedAreaList)
      handleCloseMenu();
      toast.success('Area deleted successfully!');
    } else {
      toast.error('Something went wrong');
    }
  }

  return (
    <>
      <Card>
        <Stack direction="column" alignItems={'flex-end'}>
          {handleCheckPermissionByRole(ENTITIES_TYPE.DefualtValue, ACTION_TYPE.Add) ?
            <Button variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleOpenModal}
              sx={{ marginRight: '5px', fontSize: '12px', width: '20vh' }}
            >
              New Area
            </Button> : null}
        </Stack>
        <Scrollbar>
          <TableContainer sx={{
            maxHeight: '78vh', // Set the desired fixed height
            overflowY: 'auto', // Add vertical scroll
          }}>
            <Table
              size='small'
              padding='normal'
              stickyHeader>
              <UserListHead
                headLabel={TABLE_HEAD}
              />
              {isLoading ?
                <TableRow>
                  <TableCell align="center" colSpan={4} sx={{ py: 10 }}>
                    <Paper>
                      <CircularProgress color="inherit" />
                    </Paper>
                  </TableCell>
                </TableRow>
                : <TableBody>
                  {areaList?.length ? areaList?.sort((a, b) => a.name.localeCompare(b.name)).map((row, index) => {
                    const { id, name } = row;
                    return (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{name}</TableCell>
                        <TableCell align="center">{id}</TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  }) :
                    <TableRow>
                      <TableCell align="center" colSpan={15} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph fontSize={10}>
                            No Data found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>}
                </TableBody>}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Update) ? <MenuItem onClick={handleOpenModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem> : null}

        {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Delete) ? <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteEnquiry(selectedArea?.id)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> : null}
      </Popover>

      {/* modal for Add/Edit */}
      <ModalReusable
        open={isOpenModal}
        onClose={handleCloseModal}
        header={`${selectedArea?.id ? 'Update' : 'Add'} Area`}
      >
        <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Area Id"
              variant="outlined"
              type='number'
              name="id"
              value={formik.values.id}
              onChange={formik.handleChange}
              error={formik.touched.id && Boolean(formik.errors.id)}
              helperText={formik.touched.id && formik.errors.id}
            />
            <Button variant="contained"
              type='submit'
              size='medium'
              sx={{ marginTop: '10px' }}
            >
              {selectedArea?.id ? 'Update' : 'Add'} Area
            </Button>
          </form>
        </Box>

      </ModalReusable>
    </>
  )
}

export default AreaCrudModal
import React, { useState } from 'react'
import {

  Grid,
  Container,
  Stack,
  Typography,
  Button,

} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import { titleCase } from '../utils/index';
import AreaCrudModal from '../components/modal/AreaCrudModal';
import SegmentCrudModal from '../components/modal/SegmentCrudModal';

const DefualtValuePage = () => {
  const [selectedType, setSelectedType] = useState(null);

  const handleTypeForTable = (type) => {
    setSelectedType(type);
  }

  return (<>
    <Helmet>
      <title> Defualt-Value | WhiteStone </title>
    </Helmet>

    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Area" color="info" icon={'ant-design:apple-filled'} onClick={() => handleTypeForTable("area")} />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Segment" color="success" icon={'ant-design:windows-filled'} onClick={() => handleTypeForTable("segment")} />
        </Grid>
      </Grid>
      <Container maxWidth={false} sx={{ marginBottom: '0px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={5}>
          <Typography variant="h4" gutterBottom>
            {titleCase(selectedType)}
          </Typography>
          {!selectedType?.length ? null : <Button onClick={() => setSelectedType(null)}>Close</Button>}
        </Stack>
      </Container>
      {selectedType === 'area' ?
        <AreaCrudModal />
        : selectedType === 'segment' ?
          <SegmentCrudModal /> : null}
    </Container>
  </>)
}

export default DefualtValuePage
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTime = ({ ...restProps }) => {
    return (
        <>
            <DatePicker
                {...restProps}
            />
        </>
    );
};

export default DateTime;

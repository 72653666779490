export const titleCase = (str) => {
    const txt = str?.replace(/_/g, " ")?.replace(/([A-Z])/g, " $1")
    return txt?.split(' ')
        ?.map(word => word.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');
};

export const isLoggedInAdmin = () => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.role === "admin";
}

export function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
}

export function formatCurrency(value) {
    if (value) {
        const numericValue = parseFloat((value)?.toString()?.replace(/,/g, ''));
        return numericValue?.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    }
    return '';
}

export const handleCheckPermissionByRole = (entity, action) => {
    const permissionObj = JSON.parse(localStorage.getItem('permission'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const findEntityIndex = permissionObj?.entities?.findIndex((data) => data?.name === entity);

    if (permissionObj) {
        return currentUser?.role === 'admin' ? true : permissionObj?.entities[findEntityIndex]?.permission[action];
    }
    return currentUser?.role === 'admin';

}
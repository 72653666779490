import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import moment from 'moment';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import isAuthenticated from './hooks/isAuthenticated';
import EnquiryPage from './pages/Enquiry';
import ProperyPage from './pages/Property';
import PropertyDealPage from './pages/PropertyDeal';
import DefualtValuePage from './pages/DefualtValuePage';
import { ACTION_TYPE, ENTITIES_TYPE } from './constant';
import { handleCheckPermissionByRole } from './utils';
import firestoreService from './firebase/firestoreService';


// ----------------------------------------------------------------------

export default function Router() {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {

    const lastLoginDate = moment(currentUser?.lastLoginTime); // Replace with your last login date
    const currentDate = moment(); // Current date
    const isDayDifferent = lastLoginDate.format('D') !== currentDate.format('D');

    if (currentUser?.role !== 'admin' && isDayDifferent) {
      handleChangeuserStatus();
    };

    if (currentUser?.role)
      fetchRolePermission()

  }, [pathname])

  const handleChangeuserStatus = async () => {
    const bannedStatus = { ...currentUser, status: 'banned' };
    const changeUserStatus = await firestoreService.updateDocument('users', currentUser?.id, bannedStatus);
    if (changeUserStatus) {
      localStorage.clear();
      navigate(0);
    }
  }
  const fetchRolePermission = async () => {
    try {
      const permissionValue = await firestoreService.getDocument('role-permission', currentUser?.role);
      localStorage.setItem('permission', JSON.stringify(permissionValue));
    } catch (error) {
      console.error('error', error);
    }
  };

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isAuthenticated() ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: handleCheckPermissionByRole(ENTITIES_TYPE.User, ACTION_TYPE.Read) ? <UserPage /> : <Navigate to="/dashboard" /> },
        { path: 'enquiry', element: handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Read) ? <EnquiryPage /> : <Navigate to="/dashboard" /> },
        { path: 'property', element: handleCheckPermissionByRole(ENTITIES_TYPE.Property, ACTION_TYPE.Read) ? <ProperyPage /> : <Navigate to="/dashboard" /> },
        { path: 'property-deal', element: handleCheckPermissionByRole(ENTITIES_TYPE.PropertyDeal, ACTION_TYPE.Read) ? <PropertyDealPage /> : <Navigate to="/dashboard" /> },
        { path: 'default-value', element: handleCheckPermissionByRole(ENTITIES_TYPE.DefualtValue, ACTION_TYPE.Read) ? <DefualtValuePage /> : <Navigate to="/dashboard" /> },
      ],
    },
    {
      path: 'login',
      element: !isAuthenticated() ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);


  return routes;
}
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import logoImage from '../../assests/logo.png';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const logo = (
    <Box
      component="div"
      sx={{
        width: '80px',
        height: '80px',
        display: 'inline-flex',
        aspectRatio: '1/1', // Set the desired aspect ratio
        ...sx,
      }}
      {...other}
    >
      <img src={logoImage} alt="Logo" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;

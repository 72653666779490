import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { getToken } from "firebase/messaging";
// routes
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import firestoreService, { messaging } from './firebase/firestoreService';
import './App.css';

// ----------------------------------------------------------------------

export default function App() {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  useEffect(() => {
    // request user for permission for notification 
    if (currentUser)
      requestPermission();

    // Register the service worker
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
        try {
          const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/public' });
          console.log('Service Worker registered');

        } catch (error) {
          console.error('Service Worker registration failed:', error);
        }
      });
    }
  }, [])

  const requestPermission = async () => {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      // generate token
      const token = await getToken(messaging, {
        vapidKey: "BIoRIVelFaml2td50nXalf6MnMuuwsOQjRwfsaAoaK_qR7aouKmIa7kx8gPoAxkAmkv3wa5PiRgEp6QnRWwDFN8"
      });
      if (token) {
        const userDataWithToken = { ...currentUser, token };
        handleUpadateUser(currentUser?.id, userDataWithToken)
      }
    } else if (permission === 'denied') {
      alert('you denied for the notification permission')
    }
  }

  const handleUpadateUser = async (id, values) => {
    try {
      const userData = await firestoreService.updateDocument('users', id, values);
      if (userData) {
        console.log("Notification Token saved successfully..!")
      } else {
        console.error('something went wrong')
      }
    } catch (error) {
      console.error('error', error)
    }
  }
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
          <ToastContainer />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export const USER_ROLES = [
    { name: "Admin", value: 'admin' },
    { name: "Back Office", value: 'back_office' },
    { name: "Sales Team", value: 'sales_team' },
    { name: "Staff", value: 'staff' },
    { name: "Team Leader", value: 'team_leader' },
];

export const ACTION_TYPE = {
    Add: "Add",
    Delete: "Delete",
    Read: "Read",
    Update: "Update",
};

export const ENTITIES_TYPE = {
    Enquiry: "Enquiry",
    Property: "Property",
    PropertyDeal: "Property-Deal",
    User: "User",
    DefualtValue: "Defualt-Value"
};

// export const AREA_NAME = [
//     { id: 3, name: " Bopal" },
//     { id: 4, name: " Gota" },
//     { id: 5, name: " Science City" },
//     { id: 6, name: " Satellite" },
//     { id: 7, name: " Prahlad nagar" },
//     { id: 8, name: " South Bopal" },
//     { id: 9, name: " Shela" },
//     { id: 10, name: " Shilaj" },
//     { id: 11, name: " Vastrapur" },
//     { id: 12, name: " Bodakdev" },
//     { id: 14, name: " Thaltej" },
//     { id: 15, name: " Vaishno Devi" },
//     { id: 16, name: " Applewood Township" },
//     { id: 17, name: " Makarba" },
//     { id: 18, name: " Sola" },
//     { id: 19, name: " Shivranjani" },
//     { id: 20, name: " Shyamal" },
//     { id: 21, name: " Ambli" },
//     { id: 22, name: " Drive In Cinema" },
//     { id: 24, name: " Changodar" },
//     { id: 25, name: " Hebatpur Road" },
//     { id: 26, name: " C G Road" },
//     { id: 27, name: " Naranpura" },
//     { id: 28, name: " Navrangpura" },
//     { id: 29, name: " Corporate Road" },
//     { id: 30, name: " Sarkhej" },
//     { id: 31, name: " Gandhinagar" },
//     { id: 32, name: " Sanathal" },
//     { id: 33, name: " Adani Shantigram" },
//     { id: 34, name: " Gokuldham" },
//     { id: 35, name: " North Bopal" },
//     { id: 36, name: " Chandkheda" },
//     { id: 37, name: " iscon cross road" },
//     { id: 38, name: " Memnagar" },
//     { id: 39, name: " Gurukul" },
//     { id: 43, name: " Bhadaj" },
//     { id: 44, name: " S G Highway" },
//     { id: 47, name: " Sindhu Bhavan" },
//     { id: 48, name: " Ambawadi" },
//     { id: 50, name: " Godrej garden city" },
//     { id: 53, name: " Sky City" },
//     { id: 56, name: " Ghuma" },
//     { id: 57, name: " Gulbai Tekra" },
//     { id: 62, name: " Rajpath Club Road" },
//     { id: 64, name: " Jodhpur" },
//     { id: 67, name: " Sanathal" },
//     { id: 68, name: " Sanand" },
//     { id: 69, name: " Law Garden" },
//     { id: 70, name: " Rajkot" },
//     { id: 71, name: " Rancharda" },
//     { id: 72, name: " Manipur" },
//     { id: 73, name: " Rajpath Rangoli" },
//     { id: 74, name: " WAPA" },
// ];

// export const SEGMENT_OPTIONS = {
//     residential: ['Flat', 'Villa', 'Tenament', 'Farmhouse ', 'Penthouse'],
//     commercial: ['Showroom/Shop', 'Office', 'Godown', 'Basement'],
//     plot: ['Residential plot', 'Industrial plot'],
// };

// export const SUB_SEGMENT_OPTIONS = {
//     "Flat": ['1BHK', '2BHK', '3BHK', '4BHK', '5BHK', '6BHK'],
//     "Villa": ['1BHK', '2BHK', '3BHK', '4BHK', '5BHK', '6BHK'],
//     "Tenament": ['1BHK', '2BHK', '3BHK', '4BHK', '5BHK', '6BHK'],
//     "Farmhouse": ['Farmhouse'],
//     "Penthouse": ['2BHK', '3BHK', '4BHK', '5BHK', '6BHK'],
//     "Showroom/Shop": ["Showroom"],
//     "Office": ["Office"],
//     "Godown": ["Godown"],
//     "Basement": ["Basement"],
//     "Residential plot": ["Residential plot"],
//     "Industrial plot": ["Industrial plot"]
// };

export const NFD_CHILD_ID_OPTIONS = {
    phone_call: ['CNR/CC/Switched off', 'Site Visit schedule', 'Call back request', 'Not interested', 'Already Purchased', 'On Hold', 'Deal Related Discussion', 'Option suggested', 'Closed', 'Details send', 'Requirement Understand', 'Non Service Area', 'Repeat Inquiry'],
    visit: ['Visit Done'],
    meeting: ['Meeting Done'],
    deal: ['Deal Done'],
};

// export const INITIAL_ROLE_PERMISSION = {
//     "entities": [
//         {
//             "name": "Enquiry",
//             "permission": {
//                 "Read": true,
//                 "Add": true,
//                 "Delete": true,
//                 "Update": true
//             }
//         },
//         {
//             "name": "Property",
//             "permission": {
//                 "Read": true,
//                 "Add": true,
//                 "Delete": true,
//                 "Update": true
//             }
//         },
//         {
//             "name": "Property-Deal",
//             "permission": {
//                 "Read": true,
//                 "Add": true,
//                 "Delete": true,
//                 "Update": true
//             }
//         },
//         {
//             "name": "User",
//             "permission": {
//                 "Read": true,
//                 "Add": true,
//                 "Delete": true,
//                 "Update": true
//             }
//         },
//         {
//             "name": "Defualt-Value",
//             "permission": {
//                 "Read": true,
//                 "Add": true,
//                 "Delete": true,
//                 "Update": true
//             }
//         }
//     ],
//     "role": "team_leader"
// }
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Container,
  Typography,
  TableContainer,
  IconButton,
} from '@mui/material';
// components

import { useEffect, useState } from 'react';
import moment from 'moment';
import EditNfdModal from '../components/modal/EditNfdModal';
import EditEnquiryModal from '../components/modal/EditEnquiryModal';
import firestoreService from '../firebase/firestoreService'
import { formatCurrency } from '../utils/index';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import Iconify from '../components/iconify';
import Label from '../components/label';

const TABLE_HEAD = [
  { id: 'sr.no', label: 'Sr.No.', alignRight: false },
  { id: 'addedAt', label: 'Added At', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'mobileNo', label: 'Mobile No', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'enquiryFor', label: 'For', alignRight: false },
  { id: 'source', label: 'Source', alignRight: false },
  { id: 'propertyType', label: 'Property Type', alignRight: false },
  { id: 'segment', label: 'Segment', alignRight: false },
  { id: 'measureMent', label: 'Sq feet/Sq Yard', alignRight: false },
  { id: 'bhkOffice', label: 'BHK/Office', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'budget', label: 'Budget', alignRight: false },
  { id: 'area', label: 'Area', alignRight: false },
  { id: 'remark', label: 'Remark', alignRight: false },
  { id: 'assignTo', label: 'Assign To', alignRight: false },
  { id: 'nfd', label: 'NFD', alignRight: false },
  { id: 'nfdStatus', label: 'NFD Status', alignRight: false },
  { id: 'addNfd', label: 'Add', alignRight: false },
  { id: 'subAction', label: 'W/V', alignRight: false },
];

export default function DashboardAppPage() {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const [idForshowTable, setIdForshowTable] = useState({ type: null });
  const [enquiryList, setEnquiryList] = useState([]);
  const [todayList, setTodayList] = useState([]);
  const [tomorrowList, setTomorrowList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [futureList, setFutureList] = useState([]);
  const [userList, setUserList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [isOpenForNFDModal, setIsOpenForNFDModal] = useState(false);
  const [enquiryModalFlag, setEnquiryModalFlag] = useState(false);
  const [isOpenForEnquiryShow, setIsOpenForEnquiryShow] = useState(false);
  const [AREA_NAME, setAreaName] = useState([])
  const [filterName, setFilterName] = useState('');
  const [filterByUser, setFilterByUser] = useState('all');

  useEffect(() => {
    if (!isOpenForNFDModal) {
      fetchAllEnquiry();
    }
  }, [isOpenForNFDModal])

  useEffect(() => {
    fetchAllUsers();
    fetchAllArea();

    return () => {
      setUserList([]);
    }
  }, [])

  useEffect(() => {
    if (idForshowTable.type === 'Today') {
      setEnquiryList(todayList);
    } else if (idForshowTable.type === 'Tomorrow') {
      setEnquiryList(tomorrowList);
    } else if (idForshowTable.type === 'Pending') {
      setEnquiryList(pendingList);
    } else if (idForshowTable.type === 'Future') {
      setEnquiryList(futureList);
    } else {
      setEnquiryList([]);
    }
    return () => {
      setEnquiryList([]);
    }
  }, [idForshowTable.type])

  const fetchAllEnquiry = async () => {
    try {
      setIsLoading(true);
      let enquiryList;
      if (['admin', 'back_office'].includes(currentUser?.role)) {
        const whereClauseClose = { field: 'status', operator: '!=', value: 'close' };
        enquiryList = await firestoreService.findDocuments('enquiries', whereClauseClose);
      } else {
        const whereClause = { field: 'assignTo', operator: '==', value: currentUser?.id };
        const whereClause2 = { field: 'status', operator: '!=', value: 'close' };
        enquiryList = await firestoreService.findDocuments('enquiries', whereClause, whereClause2);
      }
      if (enquiryList.length) {
        handleFilterDayType(enquiryList)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('error', error);
      setIsLoading(false);
    }
  };

  const handleFilterDayType = (enquiryData) => {
    const today = moment().startOf('day'); // Set current date to the start of the day
    const tomorrow = moment().add(1, 'day').startOf('day'); // Set tomorrow's date to the start of the day

    const filteredToday = enquiryData.filter((enquiry) => {
      if (!enquiry.nfd) return false; // Handle cases where nfd is not defined
      const nfdDate = moment(enquiry.nfd, 'YYYY-MM-DD').startOf('day');
      return nfdDate.isSame(today, 'day');
    });
    setTodayList(filteredToday);

    const filteredTomorrow = enquiryData.filter((enquiry) => {
      if (!enquiry.nfd) return false; // Handle cases where nfd is not defined
      const nfdDate = moment(enquiry.nfd, 'YYYY-MM-DD').startOf('day');
      return nfdDate.isSame(tomorrow, 'day');
    });
    setTomorrowList(filteredTomorrow);

    const filteredPending = enquiryData.filter((enquiry) => {
      if (!enquiry.nfd) return false; // Handle cases where nfd is not defined
      const nfdDate = moment(enquiry.nfd, 'YYYY-MM-DD').startOf('day');
      return nfdDate.isBefore(today);
    });
    setPendingList(filteredPending);

    const filteredFuture = enquiryData.filter((enquiry) => {
      if (!enquiry.nfd) return false; // Handle cases where nfd is not defined
      const nfdDate = moment(enquiry.nfd, 'YYYY-MM-DD').startOf('day');
      return nfdDate.isAfter(tomorrow);
    });
    setFutureList(filteredFuture);
  };

  const fetchAllArea = async () => {
    try {
      const areaData = await firestoreService.getDocument('default-values', 'area');
      const areaArray = areaData?.area;
      if (areaArray?.length) {
        setAreaName(areaArray);
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const fetchAllUsers = async () => {
    try {
      setIsLoading(true);
      const userList = await firestoreService.getAllDocuments('users');
      if (userList.length) {
        setUserList(userList);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('error', error)
      setIsLoading(false);
    }
  }

  const handleFilterByuser = (event) => {
    setFilterByUser(event.target.value);
  };

  const handleTypeForTable = (type) => {
    setIdForshowTable({ type });
  }

  const handleOpenNFDModal = (data) => {
    setSelectedEnquiry(data);
    setIsOpenForNFDModal(true);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleWhatsapp = (row) => {
    const mobileNumber = row.mobileNo; // Replace with the appropriate field containing the mobile number
    const whatsappURL = `https://wa.me/${mobileNumber}`;

    window.open(whatsappURL, '_blank');
  };

  const handleShowEnquiryModal = (data) => {
    setSelectedEnquiry(data);
    setEnquiryModalFlag(true);
    setIsOpenForEnquiryShow(true);
  }
  function applySearchFilter(array, query, userQuiery) {
    if (query || userQuiery !== 'all') {
      return array.filter((_enquiry) => {
        const nameMatches = _enquiry.name &&
          _enquiry.name.toString().trim().toLowerCase().includes(query?.toString()?.trim()?.toLowerCase());

        const mobileNoMatches = _enquiry.mobileNo &&
          _enquiry.mobileNo.toString().trim().toLowerCase().includes(query?.toString()?.trim()?.toLowerCase());

        const userMatches = userQuiery === 'all' || _enquiry.assignTo === userQuiery;

        return (nameMatches || mobileNoMatches) && userMatches;
      });
    }
    return array;
  }

  const filteredEnquiry = applySearchFilter(enquiryList, filterName, filterByUser);

  const isNotFound = !filteredEnquiry.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Dashboard | WhiteStone </title>
      </Helmet>

      <Container maxWidth={false} sx={{ overflow: 'hidden' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Today" total={todayList.length} icon={'ant-design:android-filled'} isLoading={isLoading} onClick={() => handleTypeForTable("Today")} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Tomorrow" total={tomorrowList.length} color="info" icon={'ant-design:apple-filled'} isLoading={isLoading} onClick={() => handleTypeForTable("Tomorrow")} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Future" total={futureList.length} color="success" icon={'ant-design:windows-filled'} isLoading={isLoading} onClick={() => handleTypeForTable("Future")} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Pending" total={pendingList.length} color="warning" icon={'ant-design:windows-filled'} isLoading={isLoading} onClick={() => handleTypeForTable("Pending")} />
          </Grid>
        </Grid>
        <div>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={5}>
            <Typography variant="h4" gutterBottom>
              {idForshowTable.type}
            </Typography>
            {!idForshowTable.type?.length ? null : <Button onClick={() => setIdForshowTable({ type: null })}>Close</Button>}
          </Stack>

          {idForshowTable.type ? <Card>
            {/* <UserListToolbar
              type="Enquiry"
              userCount={filteredEnquiry?.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              filterByUser={false}
            /> */}
            <UserListToolbar
              type="Enquiry"
              userCount={filteredEnquiry?.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              userList={userList}
              filterByUser={filterByUser}
              onFilterByUser={handleFilterByuser}
            />
            <Scrollbar>
              <TableContainer sx={{
                maxHeight: '85vh', // Set the desired fixed height
                overflowY: 'auto', // Add vertical scroll
              }}>
                <Table
                  size='small'
                  padding='normal'
                  stickyHeader>
                  <UserListHead
                    headLabel={TABLE_HEAD}
                  />
                  <TableBody>
                    {!filterName.length && !filteredEnquiry.length ?
                      (<TableRow>
                        <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              No Data found
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>) : filteredEnquiry?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)).map((row, index) => {
                        const { createdAt, name, mobileNo, email, enquiryFor, source, propertyType, segment, measureMent, unit, bhkOffice, status, budget, area, remark, assignTo, nfd, childNfdStatus } = row;
                        return (
                          <TableRow hover key={index} tabIndex={-1}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center" sx={{ minWidth: '130px' }}>{moment(createdAt).format('DD-MM-YYYY')}</TableCell>
                            <TableCell align="center">{name}</TableCell>
                            <TableCell align="center">{mobileNo}</TableCell>
                            <TableCell align="center">{email}</TableCell>
                            <TableCell align="center">{enquiryFor}</TableCell>
                            <TableCell align="center">{source}</TableCell>
                            <TableCell align="center">{propertyType}</TableCell>
                            <TableCell align="center">{segment}</TableCell>
                            <TableCell align="center">{`${measureMent} ${unit}`}</TableCell>
                            <TableCell align="center">{bhkOffice}</TableCell>
                            <TableCell align="center">
                              <Label color={status === 'close' ? 'error' : status === 'hold' ? 'warning' : 'success'}>{status}</Label>
                            </TableCell>
                            <TableCell align="center">{formatCurrency(budget)}</TableCell>
                            <TableCell align="center">
                              <ul>
                                {area.map((areaId, index) => (
                                  <li key={index}>{AREA_NAME.find((area) => area.id === areaId)?.name}</li>
                                ))}
                              </ul>
                            </TableCell>
                            <TableCell align="center">{remark}</TableCell>
                            <TableCell align="center">{userList.find(user => user.id === assignTo)?.name}</TableCell>
                            <TableCell align="center" sx={{ minWidth: '130px' }}>{moment(nfd).format('DD-MM-YYYY')}</TableCell>
                            <TableCell align="center" sx={{ minWidth: '130px' }}>{childNfdStatus}</TableCell>
                            <TableCell align="center">
                              <IconButton size="large" color="inherit" onClick={() => handleOpenNFDModal(row)}>
                                <Iconify icon={'ic:baseline-plus'} />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center">
                              <IconButton size="large" color="inherit" onClick={() => handleWhatsapp(row)}>
                                <Iconify icon={'logos:whatsapp-icon'} />
                              </IconButton>
                              <IconButton size="large" color="inherit" onClick={() => handleShowEnquiryModal(row)}>
                                <Iconify icon={'mdi:eye'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card> : null}
        </div>

      </Container>

      {/* modal enquiryFor enquiry */}
      <EditEnquiryModal
        selectedEnquiry={selectedEnquiry}
        setSelectedEnquiry={setSelectedEnquiry}
        setENQUIRYLIST={setEnquiryList}
        ENQUIRYLIST={enquiryList}
        enquiryModalFlag={enquiryModalFlag}
        setEnquiryModalFlag={setEnquiryModalFlag}
        userList={userList}
        isOpenForEnquiryShow={isOpenForEnquiryShow}
        setIsOpenForEnquiryShow={setIsOpenForEnquiryShow}
        AREA_NAME={AREA_NAME}
      />

      {/* modal for Edit NFD  */}
      <EditNfdModal
        isOpenForNFDModal={isOpenForNFDModal}
        setIsOpenForNFDModal={setIsOpenForNFDModal}
        selectedEnquiry={selectedEnquiry}
        setSelectedEnquiry={setSelectedEnquiry}
        ENQUIRYLIST={enquiryList}
        setENQUIRYLIST={setEnquiryList}
      />
    </>
  );
}

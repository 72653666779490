// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import {
    getFirestore,
    collection,
    doc,
    addDoc,
    setDoc,
    getDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    query,
    where,
    serverTimestamp,
} from 'firebase/firestore';
import moment from "moment";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD6PEyyb9FUy_oJLOL2QdA31eW0FVv_rPg",
    authDomain: "whitestone-9157e.firebaseapp.com",
    projectId: "whitestone-9157e",
    storageBucket: "whitestone-9157e.appspot.com",
    messagingSenderId: "997175165074",
    appId: "1:997175165074:web:369a4bdc8ba32ddb4f264b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
export const messaging = getMessaging(app);

export const sendNotification = async (token, title, body) => {
    try {
        const FIREBASE_API_KEY = "AAAA6CxFgJI:APA91bFD5XF_soIlZK7kk4bJctQFURelsJKxhtqwlRLHvAU6uEIiDidaYA1sd2oxv77jQ_Bn0H6O3RWqv6lqh16SZpsKz61tWtC1Eir3tcJ8yBjvNegwUNEo7LUto4LcIcPlKxX8hUbl";
        const message = {
            notification: {
                title,
                body,
                icon: '/public/favicon/favicon-32x32.png',
            },
            registration_ids: [token]
        };

        const response = await fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `key=${FIREBASE_API_KEY}`
            },
            body: JSON.stringify(message),
        });

        if (response.ok) {
            console.log('Notification sent successfully');
        } else {
            console.error('Failed to send push notification:', response.status, await response.text());
        }
    } catch (error) {
        console.error('Error sending notification:', error);
    }
};

// Firestore service methods
const firestoreService = {
    async addDocument(collectionName, data) {
        try {
            const dataWithTimestamp = { ...data, createdAt: moment().format() };
            const docRef = await addDoc(collection(db, collectionName), dataWithTimestamp);
            const response = { id: docRef.id, ...dataWithTimestamp };
            return response;
        } catch (error) {
            console.error('Error adding document: ', error);
            return null;
        }
    },

    async setDocument(collectionName, documentId, data) {
        try {
            const dataWithTimestamp = { ...data, createdAt: moment().format() };
            await setDoc(doc(db, collectionName, documentId), dataWithTimestamp);
            const response = { ...dataWithTimestamp };
            return response;
        } catch (error) {
            console.error('Error setting document: ', error);
            return false;
        }
    },

    async getDocument(collectionName, documentId) {
        try {
            const docRef = doc(db, collectionName, documentId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return { id: docSnap.id, ...docSnap.data() };
            }
            console.log('No such document!');
            return null;
        } catch (error) {
            console.error('Error getting document: ', error);
            return null;
        }
    },

    async getAllDocuments(collectionName) {
        try {
            const querySnapshot = await getDocs(collection(db, collectionName));
            return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
            console.error('Error getting documents: ', error);
            return null;
        }
    },

    async updateDocument(collectionName, documentId, data) {
        try {
            await updateDoc(doc(db, collectionName, documentId), data);
            return true;
        } catch (error) {
            console.error('Error updating document: ', error);
            return false;
        }
    },

    async deleteDocument(collectionName, documentId) {
        try {
            await deleteDoc(doc(db, collectionName, documentId));
            return true;
        } catch (error) {
            console.error('Error deleting document: ', error);
            return false;
        }
    },
    // Query a collection using where and orderBy clauses
    // Query a collection
    async findOneDocument(collectionName, condition) {
        try {
            const q = query(collection(db, collectionName), where(condition?.field, condition?.operator, condition?.value));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.docs || querySnapshot.docs.length === 0) {
                return null;
            }
            const docSnap = querySnapshot.docs[0];
            if (docSnap.exists()) {
                const userData = { id: docSnap.id, ...docSnap.data() };
                return userData;
            }
            return null;
        } catch (error) {
            console.error("Error querying collection: ", error);
            return null;
        }
    },
    // Find multiple list with where cluase
    async findDocuments(collectionName, condition, condition2 = false) {
        try {
            const constraints = []
            let q;
            if (condition2) {
                constraints.push(where(condition?.field, condition?.operator, condition?.value));
                constraints.push(where(condition2?.field, condition2?.operator, condition2?.value));
                q = query(collection(db, collectionName), ...constraints);
            } else {
                q = query(collection(db, collectionName), where(condition?.field, condition?.operator, condition?.value));
            }
            const querySnapshot = await getDocs(q);
            const documents = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    const docData = { id: doc.id, ...doc.data() };
                    documents.push(docData);
                }
            });
            return documents;
        } catch (error) {
            console.error("Error querying collection: ", error);
            return [];
        }
    },
    async handleAdvancedSearch(collectionName, conditions) {
        try {
            const collectionRef = collection(db, collectionName);
            let searchQuery = query(collectionRef);

            // Applying filters based on provided conditions
            conditions.forEach(({ field, operator, value }) => {
                searchQuery = query(searchQuery, where(field, operator, value));
            });

            // Execute the query
            const querySnapshot = await getDocs(searchQuery);

            // Process the documents
            const documents = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    const docData = { id: doc.id, ...doc.data() };
                    documents.push(docData);
                }
            });
            return documents;
        } catch (error) {
            console.error('Error in handleAdvancedSearch:', error);
            throw error; // Rethrow the error for handling outside of this function if needed
        }
    }
};

export default firestoreService;
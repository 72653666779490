import React, { useEffect, useState } from 'react'
import {
    Table,
    Paper,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer, Box, FormControl, CircularProgress, RadioGroup, FormControlLabel, Radio, TableHead, FormGroup, Checkbox,
} from '@mui/material';
import { toast } from 'react-toastify';
import { titleCase } from '../../utils';
import firestoreService from '../../firebase/firestoreService';

const Permission = ({ handleCloseModalForPermission, userRoles }) => {
    const [selectedRole, setSelectedRole] = useState('');
    const [rolePermissionList, setRolePermissionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchRolePermissions();

        return () => {
            setRolePermissionList([]);
        }
    }, [selectedRole])

    const fetchRolePermissions = async () => {
        try {
            setIsLoading(true);
            const permissionList = await firestoreService.getAllDocuments('role-permission');
            if (permissionList.length) {
                setRolePermissionList(permissionList)
            }
            setIsLoading(false);
        } catch (error) {
            console.error('error', error);
            setIsLoading(false);
        }
    };

    const handleUpdateRolePermission = async () => {
        // for set role ->  INITIAL_ROLE_PERMISSION 
        try {
            const permissionData = await firestoreService.setDocument('role-permission', selectedRole, rolePermissionList.find(data => data?.role === selectedRole));
            if (permissionData) {
                handleCloseModalForPermission();
                toast.success('Permission updated successfully..!')
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleOnChangePermission = (selectedRole, selectedEntity, action) => {
        setRolePermissionList((prevList) => {
            const updatedList = [...prevList];
            const roleIndex = updatedList.findIndex((item) => item.role === selectedRole);

            if (roleIndex !== -1) {
                const entityIndex = updatedList[roleIndex].entities.findIndex((entity) => entity.name === selectedEntity);

                if (entityIndex !== -1) {
                    updatedList[roleIndex].entities[entityIndex].permission[action[0]] = !action[1];
                }
            }

            return updatedList;
        });
    };

    return (
        <Box sx={{ backgroundColor: 'background.paper', boxShadow: 24, p: 4 }}>
            <FormControl component="fieldset">
                <RadioGroup value={selectedRole} onChange={handleRoleChange}>
                    {userRoles.map((role, index) => (
                        <FormControlLabel key={index} value={role.value} control={<Radio />} label={role.name} />
                    ))}
                </RadioGroup>
            </FormControl>

            {selectedRole ? (
                <TableContainer>
                    <Typography variant="h6" paragraph mt={5}>
                        Roles Permission for {titleCase(selectedRole)}
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name Of Main</TableCell>
                                <TableCell>Permission</TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ?
                            <TableRow>
                                < TableCell align="center" colSpan={2} sx={{ py: 10 }}>
                                    <Paper>
                                        <CircularProgress color="inherit" />
                                    </Paper>
                                </TableCell>
                            </TableRow> : <TableBody>
                                {rolePermissionList.find(({ role }) => role === selectedRole)?.entities?.map((entity, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{entity?.name}</TableCell>
                                        <TableCell>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    {Object.entries(entity?.permission)?.map((rolePermission, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    checked={rolePermission[1]}
                                                                    onChange={() => handleOnChangePermission(selectedRole, entity?.name, rolePermission)}
                                                                />}
                                                            label={rolePermission[0]}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>}
                        <Button variant="contained"
                            type='button'
                            size='medium'
                            sx={{ marginTop: '10px' }}
                            onClick={handleUpdateRolePermission}
                        >
                            save
                        </Button>
                    </Table>
                </TableContainer>
            ) : null}
        </Box>
    );
}

export default Permission
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
    Card,
    Table,
    Paper,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import EditEnquiryModal from '../components/modal/EditEnquiryModal';
import { formatCurrency, handleCheckPermissionByRole, } from '../utils/index';
import firestoreService from '../firebase/firestoreService';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { ACTION_TYPE, ENTITIES_TYPE } from '../constant/index';
import Label from '../components/label';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'sr.no', label: 'Sr.No.', alignRight: false },
    { id: 'addedAt', label: 'Added At', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'mobileNo', label: 'Mobile No', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'enquiryFor', label: 'For', alignRight: false },
    { id: 'source', label: 'Source', alignRight: false },
    { id: 'propertyType', label: 'Property Type', alignRight: false },
    { id: 'segment', label: 'Segment', alignRight: false },
    { id: 'measureMent', label: 'Sq feet/Sq Yard', alignRight: false },
    { id: 'bhkOffice', label: 'BHK/Office', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'budget', label: 'Budget', alignRight: false },
    { id: 'area', label: 'Area', alignRight: false },
    { id: 'remark', label: 'Remark', alignRight: false },
    { id: 'assignTo', label: 'Assign To', alignRight: false },
    { id: 'nfd', label: 'NFD', alignRight: false },
    { id: 'subAction', label: 'W/V', alignRight: false },
    { id: '', label: 'Action' },
];


export default function PropertyDealPage() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [open, setOpen] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [ENQUIRYLIST, setENQUIRYLIST] = useState([])
    const [userList, setUserList] = useState([])
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [enquiryModalFlag, setEnquiryModalFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenForEnquiryShow, setIsOpenForEnquiryShow] = useState(false);
    const [AREA_NAME, setAreaName] = useState([])

    useEffect(() => {
        fetchAllEnquiry();
        fetchAllUsers();
        fetchAllArea();

        return () => {
            setENQUIRYLIST([]);
            setUserList([]);
        }
    }, [])

    const fetchAllArea = async () => {
        try {
            const areaData = await firestoreService.getDocument('default-values', 'area');
            const areaArray = areaData?.area;
            if (areaArray?.length) {
                setAreaName(areaArray);
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const fetchAllEnquiry = async () => {
        try {
            setIsLoading(true);
            let enquiryList;
            if (['admin', 'back_office'].includes(currentUser?.role)) {
                enquiryList = await firestoreService.getAllDocuments('property-deals');
            } else {
                const whereClause = { field: 'assignTo', operator: '==', value: currentUser?.id };
                const whereClause2 = { field: 'status', operator: '!=', value: 'close' };
                enquiryList = await firestoreService.findDocuments('property-deals', whereClause, whereClause2);
            }
            if (enquiryList.length) {
                const openEnquiry = enquiryList.filter(enquiry => {
                    return enquiry.status !== 'close'
                });
                setENQUIRYLIST(openEnquiry);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('error', error)
            setIsLoading(false);
        }
    }

    const fetchAllUsers = async () => {
        try {
            setIsLoading(true);
            const userList = await firestoreService.getAllDocuments('users');
            if (userList.length) {
                setUserList(userList);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('error', error)
            setIsLoading(false);
        }
    }

    const handleDeleteEnquiry = async (id) => {
        console.log('id', id)
        try {
            setOpen(false);
            const response = await firestoreService.deleteDocument('property-deals', id);
            if (response) {
                console.log('response', response)
                setENQUIRYLIST(ENQUIRYLIST.filter(enquiry => enquiry.id !== selectedEnquiry?.id));
                setSelectedEnquiry(null);
                handleCloseMenu();
                toast.success('Enquiry deleted successfully');
            } else {
                toast.error('something went wrong')
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleOpenModal = () => {
        setEnquiryModalFlag(true);
        setOpen(false);
    };

    const handleOpenMenu = (event, data) => {
        setOpen(event.currentTarget);
        setSelectedEnquiry(data);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setSelectedEnquiry(null);
    };

    const handleWhatsapp = (row) => {
        const mobileNumber = row.mobileNo; // Replace with the appropriate field containing the mobile number
        const whatsappURL = `https://api.whatsapp.com/send?phone=91${mobileNumber}`;

        window.open(whatsappURL, '_blank');
    };

    const handleShowEnquiryModal = (data) => {
        setSelectedEnquiry(data);
        setEnquiryModalFlag(true);
        setIsOpenForEnquiryShow(true);
    }

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    function applySearchFilter(array, query) {
        if (query) {
            return filter(array, (_enquiry) => _enquiry.name?.toString()?.trim()?.toLowerCase().indexOf(query?.toString()?.trim()?.toLowerCase()) !== -1 ||
                _enquiry.mobileNo?.toString()?.trim()?.toLowerCase().indexOf(query?.toString()?.trim()?.toLowerCase()) !== -1);
        }
        return ENQUIRYLIST;
    }


    const filteredEnquiry = applySearchFilter(ENQUIRYLIST, filterName);

    const isNotFound = !filteredEnquiry.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Property Deal | WhiteStone </title>
            </Helmet>

            <Container maxWidth={false} sx={{ marginBottom: '-100px' }}>
                <Card>
                    <UserListToolbar userCount={filteredEnquiry?.length} filterName={filterName} onFilterName={handleFilterByName} type="Enquiry" />
                    <Scrollbar>
                        <TableContainer
                            sx={{
                                maxHeight: '78vh', // Set the desired fixed height
                                overflowY: 'auto', // Add vertical scroll
                            }}>
                            <Table
                                size='small'
                                padding='normal'
                                stickyHeader>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                />
                                {isLoading ?
                                    <TableRow>
                                        <TableCell align="center" colSpan={23} sx={{ py: 10 }}>
                                            <Paper>
                                                <CircularProgress color="inherit" />
                                            </Paper>
                                        </TableCell>
                                    </TableRow> : <TableBody>
                                        {!filterName.length && !filteredEnquiry.length ?
                                            (<TableRow>
                                                <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            No Data found
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>) : filteredEnquiry?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)).map((row, index) => {
                                                const { createdAt, name, mobileNo, email, enquiryFor, source, propertyType, segment, measureMent, unit, bhkOffice, status, budget, area, remark, assignTo, nfd } = row;
                                                return (
                                                    <TableRow hover key={index} tabIndex={-1}>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" sx={{ minWidth: '130px' }}>{moment(new Date(createdAt)).format('DD-MM-YYYY')}</TableCell>
                                                        <TableCell align="center">{name}</TableCell>
                                                        <TableCell align="center">{mobileNo}</TableCell>
                                                        <TableCell align="center" >{email || '-'}</TableCell>
                                                        <TableCell align="center">{enquiryFor}</TableCell>
                                                        <TableCell align="center">{source}</TableCell>
                                                        <TableCell align="center">{propertyType}</TableCell>
                                                        <TableCell align="center">{segment}</TableCell>
                                                        <TableCell align="center">{`${measureMent} ${unit}`}</TableCell>
                                                        <TableCell align="center">{bhkOffice}</TableCell>
                                                        <TableCell align="center">
                                                            <Label color={status === 'close' ? 'error' : status === 'hold' ? 'warning' : 'success'}>{status}</Label>
                                                        </TableCell>
                                                        <TableCell align="center">{formatCurrency(budget)}</TableCell>
                                                        <TableCell align="center">
                                                            <ul>
                                                                {area && area.map((areaId, index) => (
                                                                    <li key={index}>{AREA_NAME.find((area) => area.id === areaId)?.name}</li>
                                                                ))}
                                                            </ul>
                                                        </TableCell>
                                                        <TableCell align="center">{remark}</TableCell>
                                                        <TableCell align="center">{userList.find(user => user.id === assignTo)?.name}</TableCell>
                                                        <TableCell align="center" sx={{ minWidth: '130px' }}>{moment(nfd).format('DD-MM-YYYY')}</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton size="large" color="inherit" onClick={() => handleWhatsapp(row)}>
                                                                <Iconify icon={'logos:whatsapp-icon'} />
                                                            </IconButton>
                                                            <IconButton size="large" color="inherit" onClick={() => handleShowEnquiryModal(row)}>
                                                                <Iconify icon={'mdi:eye'} />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton size="large" color="inherit" onClick={(e) => handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Update) ? handleOpenMenu(e, row) : null}>
                                                                <Iconify icon={'eva:more-vertical-fill'} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>}
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Update) ? <MenuItem onClick={handleOpenModal}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem> : null}

                {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Delete) ? <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteEnquiry(selectedEnquiry?.id)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem> : null}
            </Popover>

            {/* modal enquiryFor enquiry */}
            <EditEnquiryModal
                selectedEnquiry={selectedEnquiry}
                setSelectedEnquiry={setSelectedEnquiry}
                setENQUIRYLIST={setENQUIRYLIST}
                ENQUIRYLIST={ENQUIRYLIST}
                enquiryModalFlag={enquiryModalFlag}
                setEnquiryModalFlag={setEnquiryModalFlag}
                userList={userList}
                isOpenForEnquiryShow={isOpenForEnquiryShow}
                setIsOpenForEnquiryShow={setIsOpenForEnquiryShow}
                AREA_NAME={AREA_NAME}
            />
        </>
    );
}

const isAuthenticated = () => {
    let isAuth;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && Object.keys(currentUser)?.length) {
        isAuth = true;
    } else {
        isAuth = false;
    }
    return isAuth;
}
export default isAuthenticated;
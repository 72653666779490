import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    CircularProgress,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Chip,
    Slider
} from '@mui/material';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import EditNfdModal from '../components/modal/EditNfdModal';
import EditEnquiryModal from '../components/modal/EditEnquiryModal';
import { formatCurrency, handleCheckPermissionByRole, titleCase } from '../utils/index';
import firestoreService from '../firebase/firestoreService';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { ACTION_TYPE, ENTITIES_TYPE } from '../constant/index';
import Label from '../components/label';
import ModalReusable from '../components/modal';
import DateTime from '../components/DateTime';

const TABLE_HEAD = [
    { id: 'sr.no', label: 'Sr.No.', alignRight: false },
    { id: 'addedAt', label: 'Added At', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'mobileNo', label: 'Mobile No', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'enquiryFor', label: 'For', alignRight: false },
    { id: 'source', label: 'Source', alignRight: false },
    { id: 'propertyType', label: 'Property Type', alignRight: false },
    { id: 'segment', label: 'Segment', alignRight: false },
    { id: 'measureMent', label: 'Sq feet/Sq Yard', alignRight: false },
    { id: 'bhkOffice', label: 'BHK/Office', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'budget', label: 'Budget', alignRight: false },
    { id: 'area', label: 'Area', alignRight: false },
    { id: 'remark', label: 'Remark', alignRight: false },
    { id: 'assignTo', label: 'Assign To', alignRight: false },
    { id: 'nfd', label: 'NFD', alignRight: false },
    { id: 'nfdStatus', label: 'NFD Status', alignRight: false },
    { id: 'addNfd', label: 'Add', alignRight: false },
    { id: 'subAction', label: 'W/V', alignRight: false },
    { id: '', label: 'Action' },
];

const validationSchema = Yup.object().shape({
    enquiryFor: Yup.string().required('For is required'),
    propertyType: Yup.string().required('Property Type is required'),
    segment: Yup.string().required('Segment is required'),
    bhkOffice: Yup.string().required('BHK/Office is required'),
});

export default function EnquiryPage() {
    const theme = useTheme();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [open, setOpen] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [filterByUser, setFilterByUser] = useState('all');
    const [ENQUIRYLIST, setENQUIRYLIST] = useState([])
    const [optionalENQUIRYLIST, setOptionalENQUIRYLIST] = useState([])
    const [userList, setUserList] = useState([])
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [segmentOptions, setSegmentOption] = useState({});
    const [subSegmentOptions, setSubSegmentOption] = useState({});
    const [enquiryModalFlag, setEnquiryModalFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenForNFDModal, setIsOpenForNFDModal] = useState(false);
    const [isOpenForEnquiryShow, setIsOpenForEnquiryShow] = useState(false);
    const [showCloseEnquiryFlag, setShowCloseEnquiryFlag] = useState(false);
    const [isOpenAdvanceSearch, setIsOpenAdvanceSearch] = useState(false);
    const [importExportType, setImportExportType] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [AREA_NAME, setAreaName] = useState([])
    const [startDate, endDate] = dateRange;

    const formik = useFormik({
        initialValues: {
            enquiryFor: '',
            propertyType: '',
            segment: '',
            bhkOffice: '',
            minBudget: null,
            maxBudget: null,
            area: [],
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsOpenAdvanceSearch(false);
            await handleAdvancedSearchEnquiry(values);
        },
    });

    useEffect(() => {
        fetchAllEnquiry();
        fetchAllUsers();
        fetchAllArea();

        return () => {
            setENQUIRYLIST([]);
            setUserList([]);
        }
    }, []);

    useEffect(() => {
        fetchAllSegmentMenu();

        return () => {
            setSegmentOption({});
            setSubSegmentOption({});
        }
    }, []);

    useEffect(() => {
        if (showCloseEnquiryFlag) {
            const closeEnquiry = optionalENQUIRYLIST.filter(enquiry => {
                return enquiry.status === 'close'
            });
            setENQUIRYLIST(closeEnquiry);
        } else {
            const openEnquiry = optionalENQUIRYLIST.filter(enquiry => {
                return enquiry.status !== 'close'
            });
            setENQUIRYLIST(openEnquiry);
        }
        return () => {
            setENQUIRYLIST([]);
        }
    }, [showCloseEnquiryFlag])

    const fetchAllSegmentMenu = async () => {
        try {
            const options = await firestoreService.getDocument('default-values', 'segment');
            const { segmentOptions, subSegmentOptions } = options;
            if (subSegmentOptions) {
                setSegmentOption(segmentOptions)
                setSubSegmentOption(subSegmentOptions);
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const fetchAllArea = async () => {
        try {
            const areaData = await firestoreService.getDocument('default-values', 'area');
            const areaArray = areaData?.area;
            if (areaArray?.length) {
                setAreaName(areaArray);
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const fetchAllEnquiry = async () => {
        try {
            setIsLoading(true);
            let enquiryList;
            if (['admin', 'back_office'].includes(currentUser?.role)) {
                enquiryList = await firestoreService.getAllDocuments('enquiries');
            } else {
                const whereClause = { field: 'assignTo', operator: '==', value: currentUser?.id };
                const whereClause2 = { field: 'status', operator: '!=', value: 'close' };
                enquiryList = await firestoreService.findDocuments('enquiries', whereClause, whereClause2);
            }
            if (enquiryList.length) {
                const openEnquiry = enquiryList.filter(enquiry => {
                    return enquiry.status !== 'close'
                });
                setENQUIRYLIST(openEnquiry);
                setOptionalENQUIRYLIST(enquiryList);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('error', error)
            setIsLoading(false);
        }
    }

    const fetchAllUsers = async () => {
        try {
            setIsLoading(true);
            const userList = await firestoreService.getAllDocuments('users');
            if (userList.length) {
                setUserList(userList);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('error', error)
            setIsLoading(false);
        }
    }

    const handleAdvancedSearchEnquiry = async (values) => {
        console.log("🚀  handleAdvancedSearchEnquiry  values: -->", values)
        try {
            setIsLoading(true);
            const { enquiryFor, propertyType, segment, bhkOffice, minBudget, maxBudget, area } = values;
            const conditions = [];

            if (enquiryFor) conditions.push({ field: "enquiryFor", operator: "==", value: enquiryFor });
            if (propertyType) conditions.push({ field: "propertyType", operator: "==", value: propertyType });
            if (segment) conditions.push({ field: "segment", operator: "==", value: segment });
            if (bhkOffice) conditions.push({ field: "bhkOffice", operator: "==", value: bhkOffice });
            if (minBudget && maxBudget) {
                conditions.push({ field: "budget", operator: ">=", value: minBudget });
                conditions.push({ field: "budget", operator: "<=", value: maxBudget });
            }
            if (area.length) {
                conditions.push({ field: "area", operator: "array-contains-any", value: area });
            }
            const enquiryList = await firestoreService.handleAdvancedSearch("enquiries", conditions);
            if (enquiryList.length) {
                const openEnquiry = enquiryList.filter(enquiry => {
                    return enquiry.status !== 'close'
                });
                setENQUIRYLIST(openEnquiry);
                setOptionalENQUIRYLIST(enquiryList);
            } else {
                setENQUIRYLIST([]);
                setOptionalENQUIRYLIST([]);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
        }
    };

    const handleDeleteEnquiry = async (id) => {
        try {
            setOpen(false);
            const response = await firestoreService.deleteDocument('enquiries', id);
            if (response) {
                setENQUIRYLIST(ENQUIRYLIST.filter(enquiry => enquiry.id !== selectedEnquiry?.id));
                setSelectedEnquiry(null);
                handleCloseMenu();
                toast.success('Enquiry deleted successfully');
            } else {
                toast.error('something went wrong')
            }
        } catch (error) {
            console.error('error', error)
        }
    }

    const handleOpenModal = () => {
        setEnquiryModalFlag(true);
        setOpen(false);
    };

    const handleOpenMenu = (event, data) => {
        setOpen(event.currentTarget);
        setSelectedEnquiry(data);
    };
    const handleOpenNFDModal = (data) => {
        setSelectedEnquiry(data);
        setIsOpenForNFDModal(true);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setSelectedEnquiry(null);
    };

    const handleWhatsapp = (row) => {
        const mobileNumber = row.mobileNo; // Replace with the appropriate field containing the mobile number
        const whatsappURL = `https://api.whatsapp.com/send?phone=91${mobileNumber}`;

        window.open(whatsappURL, '_blank');
    };

    const handleShowEnquiryModal = (data) => {
        setSelectedEnquiry(data);
        setEnquiryModalFlag(true);
        setIsOpenForEnquiryShow(true);
    }

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    const handleFilterByuser = (event) => {
        setFilterByUser(event.target.value);
    };

    const handleShowClosedEnquiry = () => {
        setShowCloseEnquiryFlag(!showCloseEnquiryFlag);
    }

    const handleImportExportEnquiry = (type) => {
        setImportExportType(type);
    }


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleImportEnquiry = () => {
        if (selectedFile) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const formattedData = jsonData?.slice(1).map((row) => {
                    const obj = {};
                    jsonData[0]?.forEach((key, index) => {
                        if (row[index]) {
                            obj[key] = row[index];
                        }
                    });
                    return obj;
                }).filter(data => Object.values(data).some(value => value !== undefined));
                if (formattedData) {
                    addExcelDataToFirestore('enquiries', formattedData)
                        .then(() => {
                            setImportExportType('');
                            setSelectedFile(null);
                            toast.success('Import successfully..!');
                            fetchAllEnquiry();
                        })
                        .catch((error) => {
                            console.error('Failed to add documents:', error);
                        });
                } else {
                    toast.error('Data is not in proper format');
                }
            };
            fileReader.readAsArrayBuffer(selectedFile);
        } else {
            toast.error('Select excel file');
        }
    };

    async function addExcelDataToFirestore(collectionName, formattedData) {
        const promises = formattedData.map((data) => {
            return firestoreService.addDocument(collectionName, data);
        });
        return Promise.all(promises);
    }


    const handleExportEnquiry = async () => {
        if (startDate && endDate) {
            const filterDateRange = ENQUIRYLIST.filter((enquiry) => {
                const createdAt = new Date(enquiry?.createdAt);
                return createdAt >= startDate && createdAt <= endDate;
            })?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
            const worksheet = XLSX.utils.json_to_sheet(filterDateRange);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Enquiries');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'enquiries.xlsx');
        } else {
            toast.error('Select date range for export data..!')
        }
    }

    function applySearchFilter(array, query, userQuiery) {
        if (query || userQuiery !== 'all') {
            return array.filter((_enquiry) => {
                const nameMatches = _enquiry.name &&
                    _enquiry.name.toString().trim().toLowerCase().includes(query?.toString()?.trim()?.toLowerCase());

                const mobileNoMatches = _enquiry.mobileNo &&
                    _enquiry.mobileNo.toString().trim().toLowerCase().includes(query?.toString()?.trim()?.toLowerCase());

                const userMatches = userQuiery === 'all' || _enquiry.assignTo === userQuiery;

                return (nameMatches || mobileNoMatches) && userMatches;
            });
        }
        return array;
    }

    const handleCloseModal = () => {
        setIsOpenAdvanceSearch(false)
    };

    const handleClearAllFilter = () => {
        setIsOpenAdvanceSearch(false)
        formik.resetForm();
        fetchAllEnquiry();
    }

    const filteredEnquiry = applySearchFilter(ENQUIRYLIST, filterName, filterByUser);

    const isNotFound = !filteredEnquiry.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Enquiry | WhiteStone </title>
            </Helmet>

            <Container maxWidth={false} sx={{ marginBottom: '-100px' }}>
                <Stack direction="row" mb={3}>
                    {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Delete) ?
                        <>
                            <Button variant="contained"
                                startIcon={<Iconify icon="iwwa:file-xsl" />}
                                onClick={() => handleImportExportEnquiry('import')}
                                sx={{ marginRight: '2px', fontSize: '12px' }}
                            >
                                Import Enquiry
                            </Button>
                            <Button variant="contained"
                                startIcon={<Iconify icon="bi:download" />}
                                onClick={() => handleImportExportEnquiry('export')}
                                sx={{ marginRight: '2px', fontSize: '12px' }}
                            >
                                Export Enquiry
                            </Button> </> : null}
                    {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Add) ?
                        <>
                            <Button variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={handleOpenModal}
                                sx={{ marginRight: '2px', fontSize: '12px' }}
                            >
                                New Enquiry
                            </Button>
                            <Button variant="contained"
                                startIcon={<Iconify icon="mdi:eye" />}
                                onClick={handleShowClosedEnquiry}
                                sx={{ marginRight: '2px', fontSize: '12px' }}
                            >
                                {!showCloseEnquiryFlag ? 'Closed Enquiry' : 'Open Enquiry'}
                            </Button>
                            <Button variant="contained"
                                startIcon={<Iconify icon="mdi:database-search-outline" />}
                                onClick={() => setIsOpenAdvanceSearch(true)}
                                sx={{ fontSize: '12px' }}
                            >
                                Advance Search
                            </Button>
                        </> : null}
                </Stack>

                <Card>
                    <UserListToolbar
                        type="Enquiry"
                        userCount={filteredEnquiry?.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        userList={userList}
                        filterByUser={filterByUser}
                        onFilterByUser={handleFilterByuser}
                    />
                    <Scrollbar>
                        <TableContainer
                            sx={{
                                maxHeight: '78vh', // Set the desired fixed height
                                overflowY: 'auto', // Add vertical scroll
                            }}>
                            <Table
                                size='small'
                                padding='normal'
                                stickyHeader>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                />
                                {isLoading ?
                                    <TableRow>
                                        <TableCell align="center" colSpan={23} sx={{ py: 10 }}>
                                            <Paper>
                                                <CircularProgress color="inherit" />
                                            </Paper>
                                        </TableCell>
                                    </TableRow> : <TableBody>
                                        {!filterName.length && !filteredEnquiry.length ?
                                            (<TableRow>
                                                <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            No Data found
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>) : filteredEnquiry?.sort((a, b) => showCloseEnquiryFlag ? moment(b.nfd) - moment(a.nfd) : moment(b.createdAt) - moment(a.createdAt)).map((row, index) => {
                                                const { createdAt, name, mobileNo, email, enquiryFor, source, propertyType, segment, measureMent, unit, bhkOffice, status, budget, area, remark, assignTo, nfd, childNfdStatus } = row;
                                                return (
                                                    <TableRow hover key={index} tabIndex={-1}>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" sx={{ minWidth: '130px' }}>{moment(new Date(createdAt)).format('DD-MM-YYYY')}</TableCell>
                                                        <TableCell align="center">{name}</TableCell>
                                                        <TableCell align="center">{mobileNo}</TableCell>
                                                        <TableCell align="center" >{email || '-'}</TableCell>
                                                        <TableCell align="center">{enquiryFor}</TableCell>
                                                        <TableCell align="center">{source}</TableCell>
                                                        <TableCell align="center">{propertyType}</TableCell>
                                                        <TableCell align="center">{segment}</TableCell>
                                                        <TableCell align="center">{`${measureMent} ${unit}`}</TableCell>
                                                        <TableCell align="center">{bhkOffice}</TableCell>
                                                        <TableCell align="center">
                                                            <Label color={status === 'close' ? 'error' : status === 'hold' ? 'warning' : 'success'}>{status}</Label>
                                                        </TableCell>
                                                        <TableCell align="center">{formatCurrency(budget)}</TableCell>
                                                        <TableCell align="center">
                                                            <ul>
                                                                {area && area.map((areaId, index) => (
                                                                    <li key={index}>{AREA_NAME.find((area) => area.id === areaId)?.name}</li>
                                                                ))}
                                                            </ul>
                                                        </TableCell>
                                                        <TableCell align="center">{remark}</TableCell>
                                                        <TableCell align="center">{userList.find(user => user.id === assignTo)?.name}</TableCell>
                                                        <TableCell align="center" sx={{ minWidth: '130px' }}>{moment(nfd).format('DD-MM-YYYY')}</TableCell>
                                                        <TableCell align="center" sx={{ minWidth: '130px' }}>{childNfdStatus}</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenNFDModal(row)}>
                                                                <Iconify icon={'ic:baseline-plus'} />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <IconButton size="large" color="inherit" onClick={() => handleWhatsapp(row)}>
                                                                <Iconify icon={'logos:whatsapp-icon'} />
                                                            </IconButton>
                                                            <IconButton size="large" color="inherit" onClick={() => handleShowEnquiryModal(row)}>
                                                                <Iconify icon={'mdi:eye'} />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton size="large" color="inherit" onClick={(e) => handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Update) ? handleOpenMenu(e, row) : null}>
                                                                <Iconify icon={'eva:more-vertical-fill'} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>}
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={23} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Update) ? <MenuItem onClick={handleOpenModal}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem> : null}

                {handleCheckPermissionByRole(ENTITIES_TYPE.Enquiry, ACTION_TYPE.Delete) ? <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteEnquiry(selectedEnquiry?.id)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem> : null}
            </Popover>

            {/* modal enquiryFor enquiry */}
            <EditEnquiryModal
                selectedEnquiry={selectedEnquiry}
                setSelectedEnquiry={setSelectedEnquiry}
                setENQUIRYLIST={setENQUIRYLIST}
                ENQUIRYLIST={ENQUIRYLIST}
                enquiryModalFlag={enquiryModalFlag}
                setEnquiryModalFlag={setEnquiryModalFlag}
                userList={userList}
                isOpenForEnquiryShow={isOpenForEnquiryShow}
                setIsOpenForEnquiryShow={setIsOpenForEnquiryShow}
                AREA_NAME={AREA_NAME}
            />

            {/* modal for Edit NFD  */}
            <EditNfdModal
                isOpenForNFDModal={isOpenForNFDModal}
                setIsOpenForNFDModal={setIsOpenForNFDModal}
                selectedEnquiry={selectedEnquiry}
                setSelectedEnquiry={setSelectedEnquiry}
                ENQUIRYLIST={ENQUIRYLIST}
                setENQUIRYLIST={setENQUIRYLIST}
            />

            {/* for import/export modal */}

            <ModalReusable
                open={Boolean(importExportType)}
                onClose={() => handleImportExportEnquiry('')}
                header={`${titleCase(importExportType)} Enquiry`}
            >
                <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4, minHeight: '300px' }}>
                    {importExportType === 'import' ?
                        <Stack direction={'column'} sx={{ p: 4 }}>
                            <TextField
                                type="file"
                                variant="outlined"
                                accept=".xlsx, .csv"
                                onChange={handleFileUpload}
                            />
                            <Button variant="contained" color="primary" onClick={handleImportEnquiry}>
                                Import
                            </Button>
                        </Stack> :
                        <Stack direction={'column'} sx={{ p: 4, minHeight: '370px', display: 'flex', justifyContent: 'flex-start' }}>
                            <div style={{ display: 'flex', marginBottom: '15px' }}>
                                <Typography variant="body1" style={{ marginRight: '20px' }}>Select Range:</Typography>
                                <DateTime
                                    className="custom-datepicker"
                                    selectsRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable

                                />
                            </div>
                            <Button variant="contained" size="large" color="primary" onClick={handleExportEnquiry}>
                                Export
                            </Button>
                        </Stack>}
                </Box>
            </ModalReusable>
            <ModalReusable
                open={isOpenAdvanceSearch}
                onClose={handleCloseModal}
                header={`Enquiry - Advance search`}
            >
                <Box sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 24, p: 4 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>For</InputLabel>
                            <Select
                                labelId="enquiryFor-label"
                                id="enquiryFor"
                                label="For"
                                name="enquiryFor"
                                value={formik.values.enquiryFor}
                                onChange={formik.handleChange}
                                error={formik.touched.enquiryFor && Boolean(formik.errors.enquiryFor)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="rent">Rent</MenuItem>
                                <MenuItem value="buy">Buy</MenuItem>
                            </Select>
                            {formik.touched.enquiryFor && formik.errors.enquiryFor && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.enquiryFor}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Property Type</InputLabel>
                            <Select
                                labelId="property-type-label"
                                id="propertyType"
                                label="Select Property Type"
                                name="propertyType"
                                value={formik.values.propertyType}
                                onChange={
                                    formik.handleChange}
                                error={formik.touched.propertyType && Boolean(formik.errors.propertyType)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="residential">Residential</MenuItem>
                                <MenuItem value="commercial">Commercial</MenuItem>
                                <MenuItem value="plot">Plot</MenuItem>
                            </Select>
                            {formik.touched.propertyType && formik.errors.propertyType && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.propertyType}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Segment:</InputLabel>
                            <Select
                                labelId="segment-label"
                                id="segment"
                                label="Segment:"
                                name="segment"
                                value={formik.values.segment}
                                onChange={formik.handleChange}
                                error={formik.touched.segment && Boolean(formik.errors.segment)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {segmentOptions[formik.values.propertyType]?.map((sagment, index) => (
                                    <MenuItem value={sagment} key={index}>{sagment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.segment && formik.errors.segment && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.segment}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Bhk/Office:</InputLabel>
                            <Select
                                labelId="bhk-label"
                                id="bhkOffice"
                                label="Bhk/Office:"
                                name="bhkOffice"
                                value={formik.values.bhkOffice}
                                onChange={formik.handleChange}
                                error={formik.touched.bhkOffice && Boolean(formik.errors.bhkOffice)}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {subSegmentOptions[formik.values.segment]?.map((subSegment, index) => (
                                    <MenuItem value={subSegment} key={index}>{subSegment}</MenuItem>
                                ))}
                            </Select>
                            {formik.touched.bhkOffice && formik.errors.bhkOffice && (
                                <Box sx={{ color: 'red', fontSize: '0.75rem', fontWeight: 400 }}>{formik.errors.bhkOffice}</Box>
                            )}
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                                <TextField
                                    type="number"
                                    name="minBudget"
                                    value={formik.values.minBudget}
                                    onChange={formik.handleChange}
                                    error={formik.touched.minBudget && Boolean(formik.errors.minBudget)}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    label="min-budget:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Typography>-</Typography>
                                <TextField
                                    type="number"
                                    name="maxBudget"
                                    value={formik.values.maxBudget}
                                    onChange={formik.handleChange}
                                    error={formik.touched.maxBudget && Boolean(formik.errors.maxBudget)}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    label="max-budget:"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Stack>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Area:</InputLabel>
                            <Select
                                labelId="area-label"
                                id="area"
                                label="Area:"
                                name="area"
                                value={formik.values.area}
                                onChange={formik.handleChange}
                                error={formik.touched.area && Boolean(formik.errors.area)}
                                onBlur={formik.handleBlur}
                                multiple  // Add the 'multiple' attribute to enable multiple selection
                                renderValue={(selected) => (
                                    <div>
                                        {selected?.map((value) => (
                                            <Chip key={value} label={AREA_NAME?.find((area) => area?.id === value).name} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px', // Set your desired max height
                                        },
                                    },
                                }}
                            >
                                {AREA_NAME?.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }, index) => (
                                    <MenuItem value={id} key={index}>{name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                            <Button variant="contained"
                                type='submit'
                                size='medium'
                                sx={{ marginTop: '10px' }}
                            >
                                Search Enquiry
                            </Button>
                            <Button variant="text"
                                type='button'
                                size='medium'
                                sx={{ marginTop: '10px' }}
                                onClick={handleClearAllFilter}
                            >
                                Clear all filter
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </ModalReusable>
        </>
    );
}

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

// ----------------------------------------------------------------------
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import firestoreService from '../../../firebase/firestoreService';
import Iconify from '../../../components/iconify';


export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: Yup.object({
      userName: Yup.string().min(5).max(15).required('Required'),
      password: Yup.string()
        .min(8, 'Must be 8 characters or more')
        .required('Required'),
    }),
    onSubmit: values => {
      handleClick(values);
    },
  });

  const handleClick = async (values) => {
    const whereClause = { field: 'userName', operator: '==', value: values.userName };
    const userData = await firestoreService.findOneDocument('users', whereClause);
    if (!userData) {
      toast.error('User not found');
    } else if (!(userData?.password === values.password)) {
      toast.error('Password is incorrect!')
    } else if (userData?.status === "banned") {
      toast.error("User is banned");
    } else {
      const loginTimeObj = {
        ...userData,
        lastLoginTime: moment().format()
      };
      const updateloginTimeData = await firestoreService.updateDocument('users', userData?.id, loginTimeObj);
      if (updateloginTimeData) {
        localStorage.setItem('currentUser', JSON.stringify(loginTimeObj))
        navigate('/dashboard', { replace: true });
      }
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            id="userName"
            name="userName"
            label="User name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.userName}
            helperText={formik.touched.userName && formik.errors.userName ? (
              <div>{formik.errors.userName}</div>
            ) : null}
            error={formik.touched.userName && formik.errors.userName}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
            error={formik.touched.password && formik.errors.password}
          />
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" style={{ marginTop: '24px' }}>
          Login
        </LoadingButton>
      </form >

    </>
  );
}

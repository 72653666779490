import React from 'react';
import {
    Button,
    Modal,
    Backdrop,
    Fade,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import Iconify from '../iconify/Iconify';

const paperStyle = {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    outline: 'none',
    padding: '20px 15px', // Removed padding
    maxHeight: '90vh',
    width: '600px',
    maxWidth: '90%',
    margin: 'auto',
    overflowY: 'scroll', // Changed to 'scroll' for scroll functionality
    overflowX: 'hidden', // Added to hide horizontal scroll
    '&::-webkit-scrollbar': {
        width: '0.5em', // Adjust as needed
        backgroundColor: '#F5F5F5', // Adjust as needed
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(23, 24, 24,0.5)', // Adjust as needed
    },
    '&:focus': {
        outline: 'none',
    },
};

const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
};

const ModalReusable = ({ open, onClose, header, children }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            className="modal"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableEscapeKeyDown
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <Box sx={paperStyle}>
                        <Box sx={headerStyle}>
                            <Typography variant="h4">{header}</Typography>
                            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                                <Iconify icon="material-symbols:close" />
                            </IconButton>
                        </Box>
                        {children}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ModalReusable;

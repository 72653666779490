import React, { useEffect, useState } from 'react'
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  Slider
} from '@mui/material';
import { toast } from 'react-toastify';
import Scrollbar from '../scrollbar/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import { handleCheckPermissionByRole } from '../../utils';
import { ACTION_TYPE, ENTITIES_TYPE } from '../../constant';
import firestoreService from '../../firebase/firestoreService';

const TABLE_HEAD = [
  { id: 'sr.no', label: 'Sr.No.', alignRight: false },
  { id: 'option', label: 'Options', alignRight: false },
];

const SegmentCrudModal = () => {
  const [selectedRange, setSelectedRange] = useState(1);
  const [segmentOptionsList, setSegmentOptionsList] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [openSelection, setOpenSelection] = React.useState(false);
  const [selectedSegmentOption, setSelectedSegmentOption] = useState('');

  useEffect(() => {
    if (!selectedSegmentOption.length) {
      handleClickOpen();
    }
    fetchAllSegmentMenu();

    return () => {
      setSegmentOptionsList([]);
    }
  }, [])

  const fetchAllSegmentMenu = async () => {
    try {
      setIsLoading(true);
      const options = await firestoreService.getDocument('default-values', 'segment');
      const { subSegmentOptions } = options;
      if (subSegmentOptions) {
        setSegmentOptionsList(subSegmentOptions);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('error', error)
      setIsLoading(false);
    }
  }
  const handleUpadateSegment = async () => {
    try {

      const updatedBHKArray = Array.from({ length: selectedRange }, (v, i) => `${i + 1}BHK`);
      const updatedOption = { ...segmentOptionsList }
      updatedOption[selectedSegmentOption] = updatedBHKArray;
      const areaUpdateData = await firestoreService.updateDocument('default-values', 'segment', { subSegmentOptions: updatedOption });
      if (areaUpdateData) {
        toast.success('Segment option updated successfully!');
        setSegmentOptionsList(updatedOption);
      } else {
        toast.error('something went wrong');
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleChange = (event) => {
    setSelectedSegmentOption(event.target.value);
    setOpenSelection(false);
  };

  const handleClickOpen = () => {
    setOpenSelection(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenSelection(false);
    }
  };

  return (
    <>
      <Card>
        <Typography variant="h6" paragraph fontSize={17} display={'flex'} justifyContent={'center'} align='center'>
          {selectedSegmentOption}
        </Typography>
        <div>
          <Dialog disableEscapeKeyDown open={openSelection} onClose={handleClose}>
            <DialogTitle>Select Segment Option</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 2, minWidth: 120 }}>
                  <InputLabel id="demo-dialog-select-label">Segment</InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={selectedSegmentOption}
                    onChange={handleChange}
                    input={<OutlinedInput label="Age" />}
                  >
                    {Object.keys(segmentOptionsList).map((option, index) => (
                      <MenuItem value={option} key={index}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
          </Dialog>
        </div>
        <Stack direction="row" display={'flex'} justifyContent={'space-between'} mb={'2vh'}>
          {handleCheckPermissionByRole(ENTITIES_TYPE.DefualtValue, ACTION_TYPE.Add) ? <>
            <Button onClick={handleClickOpen} variant='contained'>Select Segment option</Button>
            {selectedSegmentOption.length && ['Flat', 'Penthouse', 'Tenament', 'Villa'].includes(selectedSegmentOption) ? <div>
              <p style={{ marginLeft: '13px', marginBottom: '-10px' }}>set Range for BHK</p>
              <Slider
                aria-label="BHK"
                value={selectedRange}
                valueLabelDisplay="auto"
                onChange={(e, newValue) => setSelectedRange(newValue)}
                step={1}
                marks
                min={1}
                max={15}
                sx={{
                  width: "30vh",
                  mr: '20px',
                  ml: '20px',
                  mt: '-25px',
                  mb: '-20px'
                }}
              /><Button
                type='button'
                onClick={handleUpadateSegment}
                sx={{ marginRight: '5px', fontSize: '12px', width: '20vh' }}
              >
                save
              </Button>
            </div> : null}
          </> : null}
        </Stack>
        <Scrollbar>
          {selectedSegmentOption.length ? <TableContainer sx={{
            maxHeight: '78vh', // Set the desired fixed height
            overflowY: 'auto', // Add vertical scroll
          }}>
            <Table
              size='small'
              padding='normal'
              stickyHeader>
              <UserListHead
                headLabel={TABLE_HEAD}
              />
              {isLoading ?
                <TableRow>
                  <TableCell align="center" colSpan={2} sx={{ py: 10 }}>
                    <Paper>
                      <CircularProgress color="inherit" />
                    </Paper>
                  </TableCell>
                </TableRow>
                : <TableBody>
                  {Object.keys(segmentOptionsList).length ? segmentOptionsList[selectedSegmentOption].map((row, index) => {
                    return (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{row}</TableCell>
                      </TableRow>
                    )
                  }) :
                    <TableRow>
                      <TableCell align="center" colSpan={2} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph fontSize={10}>
                            No Data found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>}
                </TableBody>}
            </Table>
          </TableContainer> : null}
        </Scrollbar>
      </Card>
    </>
  )
}

export default SegmentCrudModal